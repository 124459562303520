import { reactive, readonly  } from 'vue';

const eventBus = reactive({
  removeSuccessAlert: false,
  events: {}
});

const emit = (event, data) => {
  if (eventBus.events[event]) {
    eventBus.events[event].forEach(callback => callback(data));
  }
};

const on = (event, callback) => {
  if (!eventBus.events[event]) {
    eventBus.events[event] = [];
  }
  eventBus.events[event].push(callback);
};

const off = (event, callback) => {
  if (eventBus.events[event]) {
    const index = eventBus.events[event].indexOf(callback);
    if (index > -1) {
      eventBus.events[event].splice(index, 1);
    }
  }
};

export default {
  eventBus: readonly(eventBus),  // Optional: make eventBus readonly externally
  emit,
  on,
  off
}; 