<template>
  <div class="hovered-btn">
    
    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" stroke="#B1B9C1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<ellipse cx="12.1338" cy="15.5015" rx="0.823327" ry="0.823327" fill="#7B8794" stroke="#7B8794" stroke-width="0.686106"/>
<path d="M10 9.87547C10.3331 8.92868 11.3004 8.36014 12.2897 8.52982C13.2789 8.6995 14.0015 9.55791 14 10.5616C14 11.9338 11.9417 12.6199 11.9417 12.6199" stroke="#7B8794" stroke-width="1.71526" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#E0E0E0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" stroke="#B1B9C1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<ellipse cx="12.1338" cy="15.5015" rx="0.823327" ry="0.823327" fill="#7B8794" stroke="#7B8794" stroke-width="0.686106"/>
<path d="M10 9.87547C10.3331 8.92868 11.3004 8.36014 12.2897 8.52982C13.2789 8.6995 14.0015 9.55791 14 10.5616C14 11.9338 11.9417 12.6199 11.9417 12.6199" stroke="#7B8794" stroke-width="1.71526" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  </div>
</template>