<template>
  <div
    class="dropdown_caret"
    :class="{ active: isSolidVisible }"
    :id="`caret${docId}`"
    @click="toggleCaret(`caret${docId}`)"
    ref="dropdown"
  > 
    <span class="ouline_caret">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M8 11L12 15L16 11"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span class="ouline_hover_caret">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 5C7 4.44772 7.44772 4 8 4H16C16.5523 4 17 4.44772 17 5V21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21V5Z"
          fill="#E0E0E0"
          stroke="#E0E0E0"
          stroke-width="8"
          :class="[`icon--${variant}`]"
        />
        <path
          d="M8 11L12 15L16 11"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span class="solid_caret">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V8Z"
          fill="#7B8794"
          stroke="#7B8794"
          stroke-width="2"
        />
        <path
          d="M8 11L12 15L16 11"
          stroke="#E0E0E0"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span class="solid_hover_caret">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V8Z"
          fill="#7B8794"
          stroke="#E0E0E0"
          :class="[`icon--${variant}`]"
          stroke-width="8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V8Z"
          fill="#7B8794"
          stroke="#7B8794"
          stroke-width="2"
        />
        <path
          d="M8 11L12 15L16 11"
          stroke="#E0E0E0"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    variant: {
      default: () => {
        return "";
      },
      type: String,
    },
  },
  data() {
    return {
      isSolidVisible: false,
      docId: Math.random(),
    };
  },
  methods: {
    toggleCaret(event) {
      document.querySelectorAll(".dropdown_caret").forEach((e) => {
        if(e.id!=event){
          e.classList.remove("active");
        }
      });

      // setTimeout(() => {
      //   this.isSolidVisible = true;
      // }, 5);
      document.getElementById(`caret${this.docId}`).classList.add('active')
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        //this.isSolidVisible = false;
        document.getElementById(`caret${this.docId}`).classList.remove('active')
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>



<style scoped lang="scss">
 
.dropdown_caret {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  .ouline_hover_caret,
  .solid_caret,
  .solid_hover_caret {
    display: none;
  }
  &:hover {
    .ouline_caret {
      display: none;
    }
    .ouline_hover_caret {
      display: block;
    }
  }
  &:focus {
    .solid_caret {
      display: block;
    }
    &:hover {
      .solid_caret {
        display: none;
      }
      .solid_hover_caret {
        display: block;
      }
    }
    .ouline_caret {
      display: none;
    }
    .ouline_hover_caret {
      display: none;
    }
  }
  &.active {
    .solid_caret {
      display: block;
    }
    &:hover {
      .solid_caret {
        display: none;
        svg {
          border-radius: 8px;
          background: #fff;
        }
      }
      .solid_hover_caret {
        display: block;
      }
    }
    .ouline_caret {
      display: none;
    }
    .ouline_hover_caret {
      display: none;
    }
  }
}
.icon {
  &--white {
    stroke: #fff !important;
    fill: #fff !important;
  }
}
</style>