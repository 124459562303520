<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Button / Delete">
        <g id="Group 3">
        <path id="Shape" d="M6 9H8H24" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Shape_2" d="M23 9C23 8.44772 22.5523 8 22 8C21.4477 8 21 8.44772 21 9H23ZM9 9C9 8.44772 8.55228 8 8 8C7.44772 8 7 8.44772 7 9H9ZM10 9C10 9.55228 10.4477 10 11 10C11.5523 10 12 9.55228 12 9H10ZM18 9C18 9.55228 18.4477 10 19 10C19.5523 10 20 9.55228 20 9H18ZM21 9V23H23V9H21ZM21 23C21 23.5523 20.5523 24 20 24V26C21.6569 26 23 24.6569 23 23H21ZM20 24H10V26H20V24ZM10 24C9.44772 24 9 23.5523 9 23H7C7 24.6569 8.34315 26 10 26V24ZM9 23V9H7V23H9ZM12 9V7H10V9H12ZM12 7C12 6.44772 12.4477 6 13 6V4C11.3431 4 10 5.34315 10 7H12ZM13 6H17V4H13V6ZM17 6C17.5523 6 18 6.44772 18 7H20C20 5.34315 18.6569 4 17 4V6ZM18 7V9H20V7H18Z" fill="#7B8794"/>
        <path id="Shape_3" d="M13 14V20" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Shape_4" d="M17 14V20" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </g>
    </svg>
</template>