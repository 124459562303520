<template>
    <div class="flex items-center">
        <div class="day-selector" @click="dayClicked(0)" v-bind:class="getClass(0, 'Sun')" style="margin-left:0px">S</div>
        <div class="day-selector" @click="dayClicked(1)" v-bind:class="getClass(1, 'Mon')">M</div>
        <div class="day-selector" @click="dayClicked(2)" v-bind:class="getClass(2, 'Tue')">T</div>
        <div class="day-selector" @click="dayClicked(3)" v-bind:class="getClass(3, 'Wed')">W</div>
        <div class="day-selector" @click="dayClicked(4)" v-bind:class="getClass(4, 'Thu')">T</div>
        <div class="day-selector" @click="dayClicked(5)" v-bind:class="getClass(5, 'Fri')">F</div>
        <div class="day-selector" @click="dayClicked(6)" v-bind:class="getClass(6, 'Sat')">S</div>
    </div>
  </template>
  
  <script setup>

    import { ref, defineEmits, defineProps, watch, computed } from 'vue';
    let props = defineProps(['selectedDays', 'availableDays']);
    let emit = defineEmits(['repeat_days_updated']);
    let days = props.selectedDays.split(',');
    let dayClicked = (index)=>{
        days[index] = days[index] == 0 ? 1 : 0;
        emit('repeat_days_updated', days.join(','));
    }

    function getClass(index, day){
        if (days[index] == 1 && props.availableDays.includes(day)){
            return 'selected';
        } else if (!props.availableDays.includes(day)){
            return 'disabled'
        } else {
            return '';
        }
    }

    watch(
        ()=>props.selectedDays, newValue=>{
            days.value = newValue.split(',');
        }
    )
  </script>
<style lang="scss">
    .flex {
        display: flex;
    }
    .items-center {
        align-items: center;
    } 
    .day-selector{
        width:34px;
        height:34px;
        float:left;
        border: 1px solid #BDBDBD;
        text-align:center;
        line-height:34px;
        margin-left:10px;
        border-radius:5px;
        color:#828282;
        cursor:pointer;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }
    .selected{
        background:#839BB8;
        color: white;
    }
    .disabled{
        background:#dfdfdf;
        color: white !important;
    }
</style>