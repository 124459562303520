<template>
  <div class="s2gtable list-table"> 
    <div
      class="d-flex justify-center search_container"
      :class="{ input_section: !thsearch && dynamicSearchHeight }"
      v-if="!thsearch"
    >
      <InfoPanel @close="showInfo = false" v-if="showInfo">
        <span>{{ infoMessage }}</span>
      </InfoPanel>
      <template v-if="state.openSearch && isGlobalSearch">
        <div ref="inputRef" class="position-relative search_bar">
          <span class="filter_icon position-absolute" @click="search"
            ><SearchIcon :variant="'white'"
          /></span>
          <button type="button" @click="clearInput" class="clear-btn">
            <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
            <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
          </button>
          <input
            class=""
            v-model="searchInput"
            placeholder="Search..."
            v-on:keyup.enter="search"
          />
        </div>
      </template>
    </div>
    <table class="table border-1 s2g-common-table">
      <thead>
        <tr>
          <th :class="{ inline_search: thsearch }"> 
            <div
              class="d-flex align-center"
              :class="{ col_disabled: disabledColumn?.first_col }"
            >
              <span
                class="common-icon"
                :class="{
                  disabled: state.openSearch,
                  cursor_pointer: !state.openSearch,
                }"
                v-if="isGlobalSearch"
                ref="searchButton"
                @click="toggleSearch"
              > 
                <SearchIcon v-show="state.openSearch === false" :variant="isWhiteIcons?'white':''" />
              </span>
            </div>
          </th>
          <template v-for="(column, index) in headers" :key="column.key">
            <th
              v-if="index == headers.length - 1"
              :style="{ width: column.width }"
              :class="[
                '',
                thsearch ? 'inline_search' : '',
                typeof disabledColumn === 'object' && typeof disabledColumn.col === 'object' && (disabledColumn.col ?? []).some(obj => obj === column.key)
                  ? 'col_disabled'
                  : '',
              ]"
            >

            <span v-if="index == 0">
                  <template v-if="showCount">
                    {{ totalItems }} {{ totalItems < 2 ? totalCountText1: totalCountText}}
                    <span v-if="selectedItemCount > 0">/{{ selectedItemCount }} Selected</span>
                  </template>
            </span>

              <div
                class="d-flex align-center justify-end"
                :class="{
                  'th_search align-center justify-space-between': thsearch,
                }"
              >
                <!---Search-->
                <span v-if="index == 0">
                  <template v-if="showCount">
                  </template>
                  <template v-else>
                    <!---Filter-->
                      <span
                        class="cursor_pointer"
                        v-if="column.isFilter && column.filterItems?.length > 0"
                      >
                        <DropdownFilter
                          :items="column.filterItems"
                          :isWhite="isWhiteIcons"
                          :value="column.title"
                          @item-selected="
                            $emit('column-filter', { e: $event, column })
                          "
                        />
                      </span>
                      <span v-else>{{ column.title }}</span>
                  </template>
                </span>
                <span v-else style="margin-right: 35px;">
                  <div
                  class="filter-cell"
                  v-if="column.isFilter && column.filterItems?.length > 0">
                  <DropdownFilter
                    :items="column.filterItems"
                    :isWhite="isWhiteIcons"
                    :value="column.title"
                    @item-selected="
                      $emit('column-filter', { e: $event, column })
                    "
                  />
                  {{column.title}}
                </div>
                </span>
                <div
                  v-if="thsearch && index == 0 && state.openSearch && isGlobalSearch"
                  ref="searchInput2"
                  class="search_bar th_search_bar"
                  :class="{ item_selected_width: selectedItemCount > 0 }"
                >
                  <span class="filter_icon position-absolute" @click="search"
                    ><SearchIcon :variant="'white'"
                  /></span>
                  <button type="button" @click="clearInput" class="clear-btn">
                    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                    </svg>
                    <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                      <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                    </svg>
                  </button>
                  <input
                    class=""
                    v-model="searchInput"
                    placeholder="Search..."
                    v-on:keyup.enter="search"
                  />
                </div>
                
                <!---Search-->
                <span
                  class="cursor_pointer"
                  v-if="isReload"
                  @click="$emit('refresh')"
                >
                  <ResetIcon :variant="isWhiteIcons?'white':''" />
                </span>
              </div>
            </th>
            <th
              :class="{col_disabled: typeof disabledColumn === 'object' && typeof disabledColumn.col === 'object' && (disabledColumn.col ?? []).some(obj => obj === column.key)}"
              :style="{ width: column.width }"
              v-else
            >
              <div
                class="d-inline-flex"
                :class="{
                  'th_search align-center justify-space-between': thsearch,
                }"
              >   
                <div
                  class="filter-cell"
                  v-if="column.isFilter && column.filterItems?.length > 0">
                  <DropdownFilter
                    :items="column.filterItems"
                    :isWhite="isWhiteIcons"
                    :value="column.title"
                    @item-selected="
                      $emit('column-filter', { e: $event, column })
                    "
                  />
                  {{column.title}}
                </div>
                <div
                  v-else
                >
                  <!---Search-->
                  <span v-if="index == 0">
                    <template v-if="showCount">{{ totalItems }} {{ totalItems < 2 ? totalCountText1: totalCountText}}
                      <span v-if="selectedItemCount > 0">/{{ selectedItemCount }} Selected</span>
                    </template>
                    <template v-else>
                      {{ column.title }}
                    </template>
                  </span>
                  <span v-else>{{ column.title }}</span>
                </div>
                <div
                  ref="searchInput2"
                  v-if="
                    thsearch && index == 0 && state.openSearch && isGlobalSearch
                  "
                  class="position-relative search_bar th_search_bar"
                  :class="{ item_selected_width: selectedItemCount > 0 }"
                >
                  <span class="filter_icon position-absolute"
                    ><SearchIcon :variant="'white'"
                  /></span>
                  <button type="button" @click="clearInput" class="clear-btn">
                    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
            <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
                  </button>
                  <input
                    v-model="searchInput"
                    placeholder="Search..."
                    v-on:keyup.enter="search"
                  />
                </div>
                <!---Search-->
              </div>
            </th>
          </template>
          <th  v-if="isRemove" align="right">
              <div class="remove_section" style="text-align: right;">
                 <!--  <slot name="is_remove_content"></slot> -->
                  <div class="d-flex align-center justify-end pr-2">
                    <span class="remove-data mx-2">Remove</span>
                    <QuestionMark v-if="!showInfo" @click="showInfo = true" class="w-24" />
                    <QuestionMarkSolid v-if="showInfo" class="w-24" />
                  </div>
                </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
          :class="[
            'disabled_row' ? item.loading : '',
            item.class,
            item.singleRemoveClass,
            item.checked ? item.checkdClass : '',
            item.disable_checkbox || isDisabledAllCheckBox?'disabled_row':''
          ]"
        >  
          <td> 
            <div class="d-flex align-center">
              <span class="indexing">
              {{ (page) + (index+1)  }}. 
              </span>
            </div>
          </td>
          <slot name="table_body" :item="{ item, index }"></slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
/**
 * @vue-event {e, item} column-filter - emit when filter from the dropdown is changes
 * @vue-event {e, item} toggle-row-select - emit when checkbox is checked/unchecked
 * @vue-event {e} select-all - emit when all checkbox is checked/unchecked
 * @vue-event {e} refresh - emit when reload icon in clicked
 */
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import SearchIcon from "../../components/icons/SearchIcon";
import ResetIcon from "../../components/icons/ResetIcon";
import Remove from "../../components/icons/Remove";
import QuestionMark from "../../components/icons/QuestionMark";
import QuestionMarkSolid from "../../components/icons/QuestionMarkSolid";
import DropdownFilter from "../../components/common/DropdownFilter";
import InfoPanel from "@/components/common/InfoPanel.vue";
import Checkbox from "@/components/common/Checkbox.vue";

// Define props
const props = defineProps({
  // global filter
  filterItems: {
    type: Array,
    default: () => [],
  },
  //header json
  
  headers: {
    type: Array,
    default: () => [],
  },
  //table data
  items: {
    type: Array,
    default: () => [],
  },

  disabledColumn: {
    type: Object,
    default: () => {},
  },

  indeterminate: {
    type: Boolean,
    default: false,
  },
  thsearch: {
    type: Boolean,
    default: false,
  },
  showCount: {
    type: Boolean,
    default: false,
  },
  isWhiteIcons: {
    type: Boolean,
    default: false,
  },
  isReload: {
    type: Boolean,
    default: true,
  },
  totalItems: {
    type: Number,
    default: null,
  },
  selectedItemCount: {
    type: Number,
    default: null,
  },
  isGlobalSearch: {
    type: Boolean,
    default: true,
  },
  isRemove: {
    type: Boolean,
    default: false,
  },
  totalCountText: {
    type: String,
    default: "Contacts",
  },
  totalCountText1: {
    type: String,
    default: "Contact"
  },
  isSelectGlobalCheckBox: {
    type: Boolean,
    default: false,
  },
  isDisabledAllCheckBox: {
    type: Boolean,
    default: false,
  },
  dynamicSearchHeight: {
    type: Boolean,
    default: true,
  },
  infoMessage: {
    type: String,
    default: "",
  },
  searchText: {
    type: String,
    default: "",
  },
  page: {
    type: Number,
    default: 0,
  },
});
// Setup component state
const state = reactive({
  openSearch: false,
});
const searchInput = ref(null);
const inputRef = ref(null);
const selectedItem = ref(0);
const searchInput2 = ref(null);
const searchButton = ref(null);
const isAllSelected = ref(false);
const showInfo = ref(false);
// Define computed properties
const isActiveGroupSection = computed(() => props.activateGroups);

// Define methods
function toggleSelect(e, item) {
  item.checked = e.target.checked;
  // Logic to handle toggle selection
  emit("toggle-row-select", { e, item });
  if (e.target.checked) {
    selectedItem.value = selectedItem.value + 1;
  } else {
    selectedItem.value = selectedItem.value - 1;
  }
  // setTimeout((e) => {
  //   const u = props.items.filter((e) => e.checked).length;
  //   selectedItem.value = u;
  // }, 100);
}

function search(event) {
  // console.log("search", searchInput.value);
  emit("search", searchInput.value);
}

function emitSelectAll($event) {
  emit("select-all", $event);

  if ($event.target.checked) {
    isAllSelected.value = true;
    selectedItem.value = props.totalItems;
    console.log("uiuu", selectedItem.value);
  } else {
    if (isAllSelected.value) {
      selectedItem.value = 0;
    }
    isAllSelected.value = false;

    
  }
}
function handleClickOutside(event) {
  if (!state.openSearch) {
    return;
  }
  try {
    //console.log(props.thsearch,searchInput2.value[0], searchInput.value)
    if (!props.thsearch) {
      if (
        !inputRef.value.contains(event.target) &&
        !searchButton.value.contains(event.target)
      ) {
        state.openSearch = false;
      }
    } else {
      if (
        !searchInput2.value[0].contains(event.target) &&
        !searchButton.value.contains(event.target)
      ) {
        state.openSearch = false;
      }
    }
  } catch (e) {
    console.log(e, searchInput);
  }
}
function toggleSearch() {
  showInfo.value = false;
  state.openSearch = true;
  emit('open-search', true)
}
function clearInput() {
  searchInput.value = "";
  search();
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
watch(
  () => props.isSelectGlobalCheckBox,
  (newValue, oldValue) => {
    console.log("New value:", newValue, "Old value:", oldValue);
    isAllSelected.value = newValue;
    if (newValue == false) {
      selectedItem.value = 0;
    }
  },
  { immediate: true }
);
watch(
  () => props.searchText,
  (newValue, oldValue) => {
    searchInput.value = newValue;
    console.log("New search:", searchInput);
  },
  { immediate: true }
);
// Emits
const emit = defineEmits([
  "column-filter",
  "toggle-row-select",
  "select-all",
  "refresh",
  "show-info",
]);
</script>

<style  lang="scss">

.list-table {
    .indexing{ 
      display: flex;
      width: 48px;
      justify-content: flex-end;
      text-align: right;
      padding-right: 21px;
      margin-left: auto;
    }
    .table {
      border-collapse: separate;
      border-spacing: 0 6px !important;
    }
    .table > :not(caption) > * > * {
      border-bottom-width: 0px;
    }
    .table > thead > tr > th {
      border-bottom-width: 2px;
    }
    .table > thead > tr > th:last-child {
      max-width: 180px;
      width: 180px;
      padding-left: 0px;
    }
    .table > thead > tr > th:first-child {
      max-width: 65px;
      width: 65px;
    }
    .table > thead > tr > th:nth-child(6) {
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
    }
    tbody tr {
        background-color: transparent !important;
        border-top: 6px solid #f2f2f2;
        border-bottom: 6px solid #f2f2f2;
        height: 38px !important;
        td {
          background-color: #fff !important;
          min-height: 38px !important;
          overflow: hidden;
          padding: 0px 8px;
          &:first-child {
            border-radius: 10px 0 0 10px;
            max-width: 65px;
          }
          &:nth-child(6) {
            text-align: center;
            padding-left: 0px;
            padding-right: 0px;
          }
          &:last-child {
            border-radius: 0 10px 10px 0;
            padding-right: 4px;
            max-width: 180px;
            padding-left: 0px;
          }
        }
    }
}

 
  
</style>