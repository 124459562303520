<template>
   <div class="c-card-group">
      <div class="c-card">
        <div class="c-card-column-3">
          <div class="c-card-item">
            <div class="c-card-title">Account Holder</div>
            <div class="c-card-content content-type-1">
              <p>Eliza Overeem</p>
              <div class="btn-group">
                <button type="button" class="btn-outline">Usage</button>
                <button type="button" class="btn-outline">Payment</button>
              </div>
            </div>
          </div>
          <div class="c-card-item">
            <div class="c-card-title">Admin</div>
            <div class="c-card-content content-type-1">
              <ul>
                <li>• Manages Operators</li>
                <li>• Views All Activity</li>
                <li>• Views Analytics</li>
              </ul>
              <div class="switch-group">
                <SwitchLg v-model="switch1" />
                <IconDetails v-show="switch1" />
                <IconEdit v-show="switch1" /> 
              </div>
            </div>
          </div>
          <div class="c-card-item">
            <div class="c-card-title">Operator</div>
            <div class="c-card-content content-type-1">
              <ul>
                <li>• Manages Contacts</li>
                <li>• Sets Geofence Messages</li>
                <li>• Views Receiver Activity</li>
              </ul>
              <div class="switch-group">
                <SwitchLg v-model="switch2" /> 
                <IconDetails v-show="switch2" />
                <IconEdit v-show="switch2" /> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="c-card">
        <div class="c-card-item">
          <div class="c-card-title">Users</div>
          <div class="c-card-content content-type-2">
             <div class="c-content-row">
                <div>Admin</div>
                <div class="c-value">1</div>
             </div>
             <div class="c-content-row">
                <div>Operator</div>
                <div class="c-value">1</div>
             </div>  
            <div class="c-card-column-2">
              <div></div>
              <div class="c-content-row">
                <div>Receivers</div>
                <div class="c-value">1</div>
             </div>
            </div>
            <div class="c-card-column-2 c-bottom-content">
              <div>
                <button type="button" class="btn-blue-filled">Add User</button>
              </div>
              <div class="c-content-row">
                <div>Total</div>
                <div class="c-value">2</div>
             </div>
            </div>
          </div>
        </div>
      </div>
   </div>

   <div class="align-center justify-center admin-table">

        <div v-if="successMessage" class="message-delete-alert-con">
          <div class="alert alert-dismissible alert-success">
            <button type="button" class="close" @click="successMessage = false" style="color:#828282">&times;</button>
            <span class="alert-headeing">Confirmation</span><br>
            A new user has been added as Operator.
          </div>
        </div>

      <S2gTable
        :indeterminate="indeterminate"
        :items="users"
        :selectedItemCount="selectedContacts"
        ref="tableRef"
        :isWhiteIcons="true"
        :headers="contactheader"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
        ]"
        
        @toggle-row-select="selectMembers"
        :thsearch="false"
        :showCount="true"
        :isReload="true"
        :totalItems="7"
        :totalCountText="'Users'"
        :totalCountText1="'Contact'"
        :dynamicSearchHeight="false"
        :isSelectGlobalCheckBox="isSelectAll"
        @select-all="selectAll"
        :page="0"
      >
        <template v-slot:table_body="{ item }">
          <td>
            <div>{{ item.item.name }}</div>
          </td>
          <td>
            <SwitchSm v-model="item.item.admin" />
          </td>
          <td>
            <SwitchSm v-model="item.item.operator" />
          </td>
          <td>
            <div style="width: 70px;" class="text-right">{{ item.item.receivers }}</div>
          </td>
          <td style="max-width: 150px; width: 150px">
            <div class="filter-cell" v-if="item.item.status == 'pending'"><ContactPending /> <span class="status-text">pending...</span></div>
            <div class="filter-cell" v-if="item.item.status == 'active'"><ContactVerified /> <span class="status-text">active</span></div>
            <div class="filter-cell" v-if="item.item.status == 'invite'">
              <UserPlus />
              <div class="invite-button">
                invite
              </div>
            </div>
          </td>
          <td style="max-width: 150px; width: 150px">
            <div class="table-actions">
              <UserDetails  />
              <span class="icon-btn">
                <EditIcon/>
              </span>
              <span class="icon-btn">
                <IconArchieve/>
              </span>
            </div>
          </td>
        </template>
      </S2gTable>
    </div>

    
</template>

<script setup>
import SwitchLg from '@/components/common/SwitchLg.vue';
import SwitchSm from '@/components/common/SwitchSm.vue';
import { ref } from 'vue';
import S2gTable from "@/components/common/S2gTable.vue";
import EditIcon from '@/components/icons/EditIcon';
import IconDetails from '@/components/icons/IconDetails';
import IconArchieve from '@/components/icons/IconArchieve';
import IconEdit from '@/components/icons/IconEdit';
import SettingDots from '@/components/icons/message_settings/SettingDots';
import UserPlus from "@/components/icons/UserPlus.vue";
import ContactPending from "@/components/icons/ContactPending.vue";
import ContactVerified from "@/components/icons/ContactVerified.vue";
import UserDetails from '@/components/common/UserDetails.vue'

const switch1 = ref(false);
const switch2 = ref(true);
const successMessage = ref(true);

const contactheader= [
      { key: "name", title: "Name",},
      { key: "admin", title: "Admin"},
      { key: "operator", title: "Operator"},
      { key: "receivers", title: "Receivers"},
      {
        key: "status",
        title: "Status",
        class: "pr-0",
        isFilter: true,
        filterItems: [
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
          { label: 'Pending', value: 'pending' },
          { label: 'Verified', value: 'verified' },
          { label: 'Invite', value: 'invite' },
        ],
      },
      { key: "details", title: "Details" },
    ]

  let users = [{
    name : "Kai Brunner",
    admin: true,
    operator: false,
    receivers : 0,
    status: "pending",
  },
  {
    name : "Nik Edmiidz",
    admin: true,
    operator: false,
    receivers : 458,
    status: "active",
  },
  {
    name : "Kishor Kumar",
    admin: true,
    operator: true,
    receivers : 1263,
    status: "active",
  },
  {
    name : "Maksym Cherkasov",
    admin: false,
    operator: true,
    receivers : 0,
    status: "invite",
  },
  {
    name : "Vladimir Federov",
    admin: false,
    operator: true,
    receivers : 580,
    status: "active",
  },
  {
    name : "Fedor Levikov",
    admin: false,
    operator: true,
    receivers : 936,
    status: "active",
  },
  {
    name : "Brandon Hope",
    admin: false,
    operator: true,
    receivers : 0,
    status: "pending",
  }]

  let indeterminate  = false;

  function selectMembers(e){

  }

  let isSelectAll = false;
</script>

<style lang="scss" scoped>
  .alert-dismissible {
    margin-bottom: 12px;
  }
 .c-card-group {
    display: grid;
    grid-template-columns: 1fr 440px;
    column-gap: 60px;
 }

 .c-card-column-3 {
    display: grid;
    grid-template-columns: 266fr 272fr 202fr;
 }

 .c-card-column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
 }

 .content-type-1 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    & .btn-group {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        gap: 22px;
        height: 85px;
    }
  }

  .c-content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #E0E0E0;
    line-height: 20px;
    & .c-value {
      font-size: 24px;
      line-height: 20px;
    }
  }

  .c-bottom-content {
    margin-top: auto;
    & .c-content-row {
      height: 36px;
      border-bottom: none;
    }
  }

  .switch-group {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 14px;
  }

 .c-card {
    background-color: #fff;
    padding: 33px 30px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    & .c-card-title {
        font-size: 16px;
        line-height: 16px;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #E0E0E0;
        font-weight: 500;
    }
    & .c-card-item {
      display: flex;
      flex-direction: column;
      flex: 1;
        & .c-card-content {
          display: flex;
          flex-direction: column;
          flex: 1;
        & ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
 }

 .btn-outline {
    display: flex;
    width: 100px;
    height: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #7B8794;
    border-radius: 5px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #000;
    transition: 0.2s all ease;
    &:hover {
      background-color: #F2F2F2;
    }
 }
 .btn-blue-filled {
    display: flex;
    min-width: 100px;
    height: 36px;
    align-items: center;
    padding: 0 17px;
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    font-size: 14px;
    line-height: 16.41px;
    cursor: pointer;
    color: #fff;
    background-color: #4066CF;
    text-transform: uppercase;
    font-weight: 500;
    transition: 0.2s all ease;
    &:hover {
      background-color: #6B87D4;
    }
 }

 .status-text {
    font-size: 13px;
    line-height: 16px;
    color: #333333;
    font-weight: 500;
 }

 .admin-table {
    margin-top: 26px;
    margin-bottom: 60px;
 }

 .invite-button {
    width: 50px;
    height: 20px;
    background: #7B8794;
    font-size: 13px;
    line-height: 16px;
    color: #F2F2F2;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
 }
</style>
