<template>
  <div class="question_icon">
    <span class="question_outline">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
       
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 23C18.5228 23 23 18.5228 23 13C23 7.47715 18.5228 3 13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23Z"
          stroke="#B1B9C1"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0901 9.99999C10.5755 8.62004 11.9855 7.7914 13.4273 8.0387C14.8691 8.28601 15.9223 9.53714 15.9201 11C15.9201 13 12.9201 14 12.9201 14"
          stroke="#7B8794"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="13.2" cy="18.2" r="1.2" fill="#7B8794" stroke="#7B8794" />
      </svg>
    </span>
    <span class="question_outline_hover">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M13 24.5C19.3513 24.5 24.5 19.3513 24.5 13C24.5 6.64873 19.3513 1.5 13 1.5C6.64873 1.5 1.5 6.64873 1.5 13C1.5 19.3513 6.64873 24.5 13 24.5Z"
          fill="#E0E0E0"
          stroke="#E0E0E0"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 23C18.5228 23 23 18.5228 23 13C23 7.47715 18.5228 3 13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23Z"
          stroke="#B1B9C1"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0901 9.99999C10.5755 8.62004 11.9855 7.7914 13.4273 8.0387C14.8691 8.28601 15.9223 9.53714 15.9201 11C15.9201 13 12.9201 14 12.9201 14"
          stroke="#7B8794"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="13.2" cy="18.2" r="1.2" fill="#7B8794" stroke="#7B8794" />
      </svg>
    </span>
  </div>
</template>
<style scoped lang="scss">
.question_icon {
  .question_outline_hover {
    display: none;
  }
  &:hover {
    .question_outline_hover {
      display: block;
    }
    .question_outline {
      display: none;
    }
  }
}
</style>