<template>
  <div class="signin-form-con" id="authpagecontainer">
      <div class="text-center site-logo">
          <SiteLogo />
      </div>
      <div class="site-tagline">
          <h5 class="font-acumin text-center text-primary fw-semibold line-h-120">
            Geofence Messaging <br> in minutes
          </h5>
      </div>
      <div class="home-view-content">
        <div class="site-btn-group">
          <button @click="handleBtnClick('signup')" type="button" class="btn-blue">Sign up</button>
          <button @click="handleBtnClick('login')" type="button" class="btn-white-outline">Log in</button>
        </div>
        <p>
          Keep people in the know<br/>
          who are on the go.<br/><br/>

          Schedule message delivery.<br/><br/>

          Know when they are heard.
        </p>
        <button style="display: none;" type="button" class="watch-now-btn">
            <BtnWatchNow />
        </button>
      </div>
     
      
  </div>
</template>

<script>
import SiteLogo from '@/components/icons/SiteLogo.vue';
import BtnWatchNow from '@/components/icons/BtnWatchNow.vue';

export default {
  name: 'authhome',
  components: { SiteLogo, BtnWatchNow },
  methods: {
    handleBtnClick(action) {
      this.$emit('btnClick', action);
    }
  },
  mounted() {
    // Check if user is logged in and redirect to home page
    if (this.$root.loggedInUser) {
      document.location.href = "/";
    } 
  },
};
</script>

<style lang="scss" scoped>
  .site-btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 41px;
    & .btn-blue {
      width: 100px;
      height: 44px;
      color: white;
      border-radius: 7px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      background-color: #4992E6;
      transition: 0.2s all ease;
      border: 2px solid #4992E6;
      &:hover {
        background-color: #97BDE9;
        border-color: #97BDE9;
      }
    }
    & .btn-white-outline {
      width: 100px;
      height: 44px;
      color: #344D7A;
      border-radius: 7px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      background-color: transparent;
      transition: 0.2s all ease;
      border: 2px solid #344D7A;;
      &:hover {
        background-color: white;
        border-color: #344D7A;;
      }
    }
  }

  .home-view-content {
    display: flex;
    flex-direction: column;
    max-width: 249px;
    margin: -28px auto 0;
    & p {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #444444;
    }
  }
</style>