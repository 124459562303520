/*Manage checked Unchecked */
export function manageUnSelectedItems(event, item, key, data) {
  if (!event.target.checked) {
    data.push(item);
  } else {
    data = removeRecord(data, item, key);
  }
  return data;
}
export function manageSelectedItems(event, item, key, data) { 
  try {
    if (event.target.checked) {
      data.push(item);
    } else {
      data = removeRecord(data, item, key);
    } 
  } catch (e) {
    console.log(e);
  }
  return data;
}
 
export function manageSingleChecked(
  event,
  item,
  key,
  data,
  selectedCounts,
  isSelectAll,
  totalItem
) {
  data = data.map((element) => { 
    if (item[key] === element[key]) {
      element.checked = event.target.checked;
    }
    return element;
  });  
  //checked if needs to activate intermediate
  const u = data.every((e) => e.checked);  
  let indeterminate =   !u;
  //update selected contact ids
  if (!event.target.checked) {
    selectedCounts = selectedCounts - 1;
    if (isSelectAll) {
      indeterminate = true;
    }
  } else {
    selectedCounts = selectedCounts + 1;
  }
  if(selectedCounts>0 && totalItem>selectedCounts){
    indeterminate = true;
  }
  if(selectedCounts>0 && totalItem==selectedCounts){
    isSelectAll = true;
    indeterminate = false;
  }
  if(selectedCounts==0){
    indeterminate = false;

  }
  return { selectedCounts, indeterminate, data, isSelectAll };
}
export function manageRowDefaultSelection(isSelectAll,item,selectedItem,unSelectedItem,key){
  return (isSelectAll && !unSelectedItem.some((e)=>e[key]==item[key])) || selectedItem.some((e)=>e[key]==item[key])
}

function removeRecord(checkeddata, item, key) {
  const indexToRemove = checkeddata.findIndex((r) => r[key] == item[key]);
  checkeddata.splice(indexToRemove, 1);
  return checkeddata;
}
