<template>
  <v-switch v-model="value" density="compact" hide-details @click="emitClicked" inset></v-switch>
</template>

<script>
export default {
  props: { 
    model: {
      default: () => {
        return false;
      },
      type: Boolean,
    },
  },
  data() {
    return {
      value: this.model,
    };
  },
  methods:{
    emitClicked(e){
      this.$emit('clicked', e)
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-switch {
    .v-input__control {
      top: 0px;
      
    }
  }
  .v-selection-control {
    min-height: 24px;
  }
  .v-selection-control__input {
    transform: translateX(-7px);
  }
  .v-selection-control--dirty .v-selection-control__input {
    transform: translateX(7px);
  }
  .v-switch__track {
    height: 15px;
    min-width: 30px;
    background-color: #fff;
    border: 2px solid #7b8797;
  }
  .v-switch__thumb {
    width: 11px;
    height: 11px;
    background: #7b8797;
  }
  .v-selection-control--dirty {
    .v-switch__track {
      height: 16px;
      min-width: 30px;
    }
    .v-switch__thumb {
      width: 8px;
      height: 8px;
    }
  }
}
</style>
