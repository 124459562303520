<template>
  <div class="router-view-wrapper" v-if="showlogin">
    <div class="landing-page app-container d-flex justify-content-between">
      <div class="landing-text-section">
        <h2 class="font-roboto text-primary">Smart Reminders <br/> <span>Right place. Right time.</span></h2>
        <h4 class="font-roboto">Location-based audio messages <br/>
          <span>for work on properties and job sites <br/>
            when timing is everything.</span>
        </h4>
      </div>
      <div class="landing-login-form-section">
        <Auth/>
      </div>
    </div>
    <Footer :classValue="'home-footer'"/>
  </div>
</template>

<script>
import Auth from '../components/Auth.vue'
import Footer from '../components/common/Footer.vue'
import { ref } from 'vue';
export default {
  name: 'AuthView',
  components: { Auth, Footer },
  data() {
    return {
      showlogin: false
    }
	},
  created(){
    //setTimeout(()=>{
      this.showlogin = ref(this.$router.currentRoute).value.path == '/login' || 
                       ref(this.$router.currentRoute).value.path == '/terms' ||
                       ref(this.$router.currentRoute).value.path == '/privacy' 

   // }, 10)

   this.otherPages = ref(this.$router.currentRoute).value.path == '/terms' ||
                       ref(this.$router.currentRoute).value.path == '/privacy'
  }
}
</script>

<style lang="scss" scoped>

.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
</style>