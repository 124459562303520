<template>
  <label
    :class="[disabled ? 'disable_checkbox' : '']"
    class="c-checkbox cursor_pointer"
    :for="random"
  >
    <template v-if="!indeterminate">
      <span class="unchecked_checkbox" v-if="!isChecked">
        <div class="open_checked">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              stroke="#7B8794"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="hover_checked">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              fill="#E0E0E0"
              stroke="#E0E0E0"
              stroke-width="8"
              stroke-linecap="round"
              stroke-linejoin="round"
              :class="[`icon--${variant}`]"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              stroke="#7B8794"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </span>
      <span class="checked_checkbox" v-if="isChecked">
        <div class="open_checked">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              fill="#7B8794"
              stroke="#7B8794"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.6364 9L10.6364 17L7 13.3636"
              stroke="#E0E0E0"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="hover_checked">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              fill="white"
              stroke="#E0E0E0"
              :class="[`icononlystrok--${variant}`]"
              stroke-width="8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              fill="#7B8794"
              stroke="#7B8794"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.6364 9L10.6364 17L7 13.3636"
              stroke="#E0E0E0"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </span>
    </template>
    <template v-if="indeterminate">
      <div class="open_checked">
        <span class="indeterminate_checkbox">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
         
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
              fill="#7B8794"
              stroke="#7B8794"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 13H17"
              stroke="#E0E0E0"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <div class="hover_checked">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
            fill="white"
            stroke="#E0E0E0"
            :class="[`icononlystrok--${variant}`]"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z"
            fill="#7B8794"
            stroke="#7B8794"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 13H17"
            stroke="#E0E0E0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
    <input
      type="checkbox"
      v-model="isChecked"
      hidden
      :id="random"
      @change="toggleCheckbox"
    />
  </label>
</template>

<script>
export default {
  props: ["value", "indeterminate", "disabled", "variant"],
  data() {
    return {
      isChecked: this.value,
      random: Math.random(),
    };
  },
  computed: {
    currentCheckboxSVG() {
      if (this.indeterminate) {
        return "<svg>...</svg>"; // SVG code for indeterminate state
      } else if (this.isChecked) {
        return "<svg>...</svg>"; // SVG code for checked state
      } else {
        return "<svg>...</svg>"; // SVG code for unchecked state
      }
    },
  },
  methods: {
    toggleCheckbox(e) {
      if (this.indeterminate) {
        //this.indeterminate = false;

        this.$emit("change-checked", e);
      } else if (this.isChecked) {
        //this.indeterminate = false;
        //.isChecked = false;
        this.$emit("change-checked", e);
      } else {
        //this.isChecked = true;
        this.$emit("change-checked", e);
      }
    },
    updateState() {
      // Sync indeterminate state if necessary
      if (this.indeterminate && this.isChecked) {
        //this.indeterminate = false;
      }
    },
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue;
    },
  },
};
</script>

<style lang="scss">
.c-checkbox {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  .hover_checked {
    display: none;
  }
  &:hover {
    .open_checked {
      display: none;
    }
    .hover_checked {
      display: block;
    }
  }
  &.disable_checkbox {
    pointer-events: none;
    opacity: 0.5;
  }
}
.icon {
  &--white {
    stroke: #fff !important;
    fill: #fff !important;
  }
}
.icononlystrok {
  &--white {
    stroke: #fff !important;
  }
}
</style>
