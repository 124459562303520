<template>
<svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="30" rx="6" fill="white"/>
<path d="M33.16 25.94V23.94C33.16 21.73 31.37 19.94 29.16 19.94H21.16C18.95 19.94 17.16 21.73 17.16 23.94V25.94" stroke="#49454F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.16 15.94C27.37 15.94 29.16 14.15 29.16 11.94C29.16 9.73 27.37 7.94 25.16 7.94C22.95 7.94 21.16 9.73 21.16 11.94C21.16 14.15 22.95 15.94 25.16 15.94Z" stroke="#49454F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 17 11)" fill="#49454F" stroke="#49454F" stroke-width="0.75"/>
<circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 13 11)" fill="#49454F" stroke="#49454F" stroke-width="0.75"/>
<circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 9 11)" fill="#49454F" stroke="#49454F" stroke-width="0.75"/>
</svg>
</template>
