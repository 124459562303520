<template>
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8486_8660)">
<path d="M42.74 37.91V34.91C42.74 31.595 40.055 28.91 36.74 28.91H24.74C21.425 28.91 18.74 31.595 18.74 34.91V37.91" stroke="#344D7A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.74 22.91C34.055 22.91 36.74 20.225 36.74 16.91C36.74 13.595 34.055 10.91 30.74 10.91C27.425 10.91 24.74 13.595 24.74 16.91C24.74 20.225 27.425 22.91 30.74 22.91Z" stroke="#344D7A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 18.5 15.5)" fill="#344D7A" stroke="#344D7A" stroke-width="1.125"/>
<circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 12.5 15.5)" fill="#344D7A" stroke="#344D7A" stroke-width="1.125"/>
<circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 6.5 15.5)" fill="#344D7A" stroke="#344D7A" stroke-width="1.125"/>
</g>
<defs>
<clipPath id="clip0_8486_8660">
<rect width="45" height="45" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>
</template>
