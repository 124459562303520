<template>
    <div class="app-download-page">
        <div class="page-content-con">
            <header class="page-header">
                <h5>Phone / Safe area</h5>
            </header>
            <main class="page-content">
                <div class="content-con">
                    <div class="site-logo-con">
                        <SiteLogo/>
                    </div>
                    <div class="text-con">
                        <h4>Download the mobile app.</h4> <br/><br/>
                        <h4>Receive location-based text-to-speech messages from</h4>
                        <h4>{{userName}}</h4>
                    </div>
                    <div class="download-btns-con">
                        <a href="" class="download-app-btn">
                            <AppStoreBtn/>
                        </a>
                        <a href="" class="download-app-btn">
                            <PlayStoreBtn/>
                        </a>
                    </div>
                </div>
            </main>
            <footer class="page-footer">
                <div class="footer-item">
                    <span @click="showAboutModal = true" class="nav-link">About</span>
                    <a href="mailto:info@sig2labs.com" class="nav-link">Contact</a>
                    <span @click="showTermsModal = true" class="nav-link">Terms</span>
                    <span @click="showPrivacyModal = true" class="nav-link">Policy</span>
                </div>

                <div class="copyrights">
                    <p>Copyright © Sig2 Labs Inc. All rights reserved</p>
                </div>
                <div class="bottom-bar"></div>
            </footer>
        </div>

        <div data-app>
            <AboutModal v-model="showAboutModal" />
            <TermsConditionsModal v-model="showTermsModal"/>
            <PrivacyPolicyModal v-model="showPrivacyModal"/>
        </div>
    </div>
</template>
<script>
import SiteLogo from '../../components/icons/SiteLogo.vue'
import AppStoreBtn from '../../components/icons/AppstoreButton.vue'
import PlayStoreBtn from '../../components/icons/PlaystoreButton.vue'
import AboutModal from '../../components/modals/AboutModal.vue';
import TermsConditionsModal from '../../components/modals/TermsConditionsModal.vue';
import PrivacyPolicyModal from '../../components/modals/PrivacyPolicyModal.vue';
export default {
    name: 'DownloadApp',
    components:{SiteLogo, AppStoreBtn, PlayStoreBtn, AboutModal, TermsConditionsModal, PrivacyPolicyModal},
    data() {
        return {
            userName: 'Nik Edmiidz.',
            showAboutModal: false,
            showTermsModal: false,
            showPrivacyModal: false,
        };
    },
}
</script>
<styles lang="scss" scoped>
    .app-download-page{
        padding: 0;
        background: #F2F2F2;
        .page-content-con{
            max-width: 430px;
            margin: 0 auto;
            .page-header{
                width: 100%;
                height: 59px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 92, 0, 0.2);
                h5{
                    color: #FF5C00;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height:  16px;
                }
            }
            .page-content{
                padding: 47px 0 70px;
                .content-con{
                    max-width: 340px;
                    margin: 0 auto;
                    padding: 27px 40px 40px;
                    background: #ffffff;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .site-logo-con{
                        max-width: 59.891px;
                        margin-bottom: 34px;
                        svg{
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                    .text-con{
                        max-width: 237px;
                        h4{
                            color: #000;
                            text-align: center;
                            font-family: 'Roboto';
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 30px;
                        }
                    }
                    .download-btns-con{
                        margin-top: 95px;
                        display: flex;
                        flex-direction: column;
                        gap: 39px;
                        .download-app-btn{
                            width: 100%;
                            display: block;
                            svg{
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

            }
            .page-footer{
                width: 100%;
                .footer-item{
                    width: 100%;
                    max-width: 340px;
                    margin: 0 auto;
                    padding: 12px 22.5px;
                    border-radius: 10px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .nav-link{
                        text-decoration: none;
                        cursor: pointer;
                        color: #344D7A;
                        text-align: center;
                        font-family: 'Roboto';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }
                .copyrights{
                    width: 100%;
                    margin-top: 52px;
                    margin-bottom: 14px;
                    p{
                        text-align: center;
                        color: #344D7A;
                        text-align: center;
                        font-family: 'Roboto';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px; 
                    }
                }
                .bottom-bar{
                    width: 100%;
                    background: rgba(255, 92, 0, 0.2);
                    height: 34px;
                }
            }
        }
    }
</styles>