<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Button / Edit">
      <g id="Group 2">
        <path
          id="Shape"
          d="M24 17.66V23C24 24.1046 23.1046 25 22 25H8C6.89543 25 6 24.1046 6 23V9C6 7.89543 6.89543 7 8 7H13.34"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Shape_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21 6L25 10L15 20H11V16L21 6Z"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
</template>