<template>
	<div>
	  <div class="router-view-wrapper">
		<div class="landing-page app-container d-flex justify-content-between">
		  <div class="landing-text-section">
        <h2 class="font-roboto text-primary">Smart Reminders <br/> <span>Right place. Right time.</span></h2>
         
      </div>
	  
		</div>
		<Footer :classValue="'home-footer'"/>
	  </div>
	  <div data-app>
		  <WishlistModal v-model="showModal" :usersname="usersname" />
	  </div>
	</div>
  </template>

<script>
import WishlistModal from '../components/modals/WishlistModal.vue'
import Footer from './common/Footer.vue';
//import 'vue-material-design-icons/styles.css';
export default{
	name: "authnotallowed",
	components:{Footer, WishlistModal},
	data: () => ({
		usersname : "",
		showModal: true
	}),
	mounted(){
		console.log('no auth ::', this.$root)
	    // if user is added redirect back to home page
		if (this.$root.loggedInUser && !this.$root.unauthorizeuser){
			document.location.href="/";
		}
    this.usersname = this.$root.loggedInUser;

	},
	methods :{
		// logout(){
		// 	this.$root.logout();
		// }
	}

}

</script>

<style lang="scss" scoped>
.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
</style>