<template>
<svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8486_8644)">
<path d="M22.5 18.672C33.9221 18.672 41.8706 23.8667 41.8706 28.5138C41.8706 33.1609 33.9064 38.3556 22.5 38.3556C11.0935 38.3556 3.12932 33.1609 3.12932 28.5138C3.12932 23.8667 11.0935 18.672 22.5 18.672ZM22.5 15.5427C10.0765 15.5427 -3.05176e-05 21.3476 -3.05176e-05 28.5138C-3.05176e-05 35.68 10.0765 41.4849 22.5 41.4849C34.9235 41.4849 45 35.68 45 28.5138C45 21.3476 34.9235 15.5427 22.5 15.5427Z" fill="#4992E6"/>
<path d="M34.8296 27.575C34.2819 27.575 33.75 27.4186 33.218 27.1213L27.0532 23.6477L23.4544 27.0117L23.2979 27.1056C23.0007 27.2777 22.6721 27.3716 22.3278 27.3716C21.7489 27.3716 21.2013 27.1213 20.7788 26.6832C20.3564 26.2294 20.1217 25.6505 20.1217 25.0559V8.73636C20.1217 7.59415 20.6224 6.65535 21.4829 6.15465C21.8741 5.91995 22.3435 5.81042 22.8129 5.81042C23.3605 5.81042 23.8925 5.96689 24.4245 6.26418L35.0486 12.3508C36.4881 13.18 37.5365 14.9794 37.5365 16.638V24.6491C37.5365 25.7913 37.0358 26.7301 36.1596 27.2308C35.7684 27.4655 35.299 27.575 34.8452 27.575H34.8296Z" fill="white"/>
<path d="M22.7972 7.35942C23.0632 7.35942 23.3449 7.43765 23.6265 7.60977L34.2506 13.6963C35.2051 14.244 35.9405 15.527 35.9405 16.6223V24.6334C35.9405 25.2123 35.7371 25.6504 35.3459 25.8695C35.1894 25.9634 35.0017 26.0103 34.8139 26.0103C34.5479 26.0103 34.2663 25.9321 33.9846 25.76L26.8028 21.7075L22.4999 25.7443C22.4999 25.7443 22.3748 25.7913 22.2965 25.7913C22.1557 25.7913 22.0149 25.7287 21.8897 25.5879C21.7489 25.4314 21.655 25.228 21.655 25.0402V8.73633C21.655 8.1574 21.8584 7.71929 22.2496 7.50024C22.4061 7.40636 22.5938 7.35942 22.7816 7.35942M22.7816 4.23007C22.0462 4.23007 21.3108 4.41783 20.6849 4.79335C19.3393 5.57569 18.5257 7.04648 18.5257 8.73633V25.0402C18.5257 26.026 18.9325 27.0117 19.6209 27.7471C20.325 28.4825 21.2951 28.9206 22.2965 28.9206C22.9068 28.9206 23.517 28.7641 24.049 28.4512L24.3619 28.2635L24.6279 28.0131L27.2565 25.5566L32.4356 28.4825C33.171 28.905 34.0003 29.1397 34.7983 29.1397C35.5337 29.1397 36.2691 28.9519 36.8949 28.592C38.2562 27.8097 39.0542 26.3389 39.0542 24.6491V16.6379C39.0542 14.4317 37.7086 12.116 35.7997 11.0051L25.1599 4.91853C24.4088 4.48042 23.5796 4.26137 22.7659 4.26137L22.7816 4.23007Z" fill="#4992E6"/>
<path d="M26.8966 15.8712L31.0273 18.2495" stroke="#4992E6" stroke-width="3.12935" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_8486_8644">
<rect width="45" height="45" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
</template>
