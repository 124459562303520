// Styles
import '@mdi/font/css/materialdesignicons.css'
//import 'vuetify/styles'
//import '@/assets/scss/main.scss'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  icons: {
    iconfont: 'md',  // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    defaultTheme: "light", 
 
  themes: {
    light: {
      colors:{
        primary: "#344D7A",
        //secondary: "#b0bec5",
        //accent: "#8c9eff",
        //error: "#b71c1c",
      }
    },
    dark: {
      colors:{
        /* primary: "#4682b4",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c", */
      }
    }
  }
},
})
