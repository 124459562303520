 <template>
<svg  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 30 30" style="enable-background:new 0 0 30 30; width: 30px; height: 30px;" xml:space="preserve">
<g>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="#7B8794" class="st0" d="M18,3h-6c-5,0-9,4-9,9v6c0,5,4,9,9,9h6c5,0,9-4,9-9v-6C27,7,23,3,18,3z M15.1,7.2c2.3,0,4.1,1.9,4.1,4.1
		c0,2.3-1.9,4.1-4.1,4.1S11,13.6,11,11.3C11,9,12.9,7.2,15.1,7.2z M22.3,22.9c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8v-1.7
		c0-1.4-1.1-2.5-2.5-2.5h-6.6c-1.4,0-2.5,1.1-2.5,2.5v1.7c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8v-1.7c0-2.3,1.9-4.1,4.1-4.1h6.6
		c2.3,0,4.1,1.9,4.1,4.1V22.9z"/>
	<path class="st0" fill="#7B8794" fill-rule="evenodd" clip-rule="evenodd"  d="M15.1,13.8c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5S13.8,13.8,15.1,13.8z"/>
</g>
</svg>
</template>
