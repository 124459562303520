<template>
  <div class="hovered-btn">

    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
    </svg>

    <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
      <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
    </svg>

  </div>
</template>