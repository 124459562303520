<template>
  <div class="d-flex align-center justify-space-between confirm_panel">
    <div class="left_section"> <b>Confirm:</b> {{text}}</div>
    <div class="right_section">
        <label class="cancel mx-2" @click="cancel">CANCEL</label>
        <label class="success mx-2" @click="success">YES</label>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        text: {
            default() {
                return ''
            },
            type: String
        }
    },
    methods: {
    cancel() {
      this.$emit("confirmation-cancel");
    },
    success(){
      this.$emit("confirmation-success");
    }
    }
}
</script>

<style lang="scss" scoped>
.confirm_panel {
  height: 50px;
  border-radius: 10px;
  padding: 13px;
  background: #d6fcc4;
  .left_section {
    color: #333;
    font-size: 16px;
  }
  .cancel{
    color:#828282;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  .success{
    color: #2F80ED;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

  }
}
</style> 