<template>
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="30" rx="6" fill="#25AC1E"/>
    <path d="M33.16 25.94V23.94C33.16 21.73 31.37 19.94 29.16 19.94H21.16C18.95 19.94 17.16 21.73 17.16 23.94V25.94" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.16 15.94C27.37 15.94 29.16 14.15 29.16 11.94C29.16 9.73 27.37 7.94 25.16 7.94C22.95 7.94 21.16 9.73 21.16 11.94C21.16 14.15 22.95 15.94 25.16 15.94Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.48999 9.88995C8.80999 11.45 9.00999 13.67 7.96999 15.43" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.8 17.52C17.18 13.88 16.83 9.79996 14.84 6.45996" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.92 16.48C13.08 13.76 12.8 10.63 11.17 8.16998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
