<template>
	<div class="message-groups form-elements">
		<div class="form-group" style="width: 100%;">
			<!-- Duration -->
			<label class="form-label mb-0">Groups</label>
			<div class="form-control static-control">
				{{selectedGroupsCount}} Selected
				<button class="edit-icon-button" type="button" @click="showEditModal"><EditIcon/></button>
        </div>
			<MessageGroupAssignModal 
			:showSettings="showAboutModal"
			:isSelectAllGroups = "isSelectAllGroups"
			:selectedGroupsIds = "selectedGroupsIds"
			:groupTypesList = "groupTypesList"
			@closed="showAboutModal = false" 
			@group-selection-saved="onSelectionSaved"
			/> 
		</div>
	</div>
</template>

<script setup>
	const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
	import {computed, defineProps, ref, defineEmits, onMounted, getCurrentInstance } from 'vue';
	import { useRoute } from 'vue-router';
	import EditIcon from './icons/EditIcon';
	import MessageGroupAssignModal from './modals/MessageGroupAssignModal';
	let showAboutModal = ref(false);
	let selectedGroupsCount = ref(0);
	let selectedGroupsIds = ref([]);
	let selectedGroupNames = ref('');
	let isSelectAllGroups = ref(false);
	let isNewMessage = ref(true);
	let groupTypesList = [];
	const instance = getCurrentInstance();
	const route = useRoute();
	const groupListRef = ref(null);
	let emits = defineEmits(['group-selection-saved'])
	function showEditModal(){
		showAboutModal.value = true;
	}
	function onSelectionSaved(messageGroups){
		//do nothing if no selection
		if (typeof(messageGroups.checkedItems) == "undefined" || messageGroups.checkedItems.length == 0){
			selectedGroupsCount.value = 0;
			selectedGroupsIds.value = "";
			selectedGroupNames.value = ""; 
			emits('group-selection-saved', 
				{"ids": "",
				 "all" : "0",
				 "names": ""
				}
			);
		}
		
		//Assuming the perpage is 5, so if selection is less than 6, and even the isSelectedAll is checked, we will not go to fetch
		isSelectAllGroups.value = messageGroups.isSelectAll && messageGroups.uncheckedItems.length == 0;
		
		if (!messageGroups.isSelectAll){
			selectedGroupsCount.value = messageGroups.checkedItems.filter(obj => obj.Status == "active").length;
			selectedGroupsIds.value = messageGroups.checkedItems.filter(obj => obj.Status == "active");
			selectedGroupNames.value = messageGroups.checkedItems.filter(obj => obj.Status == "active").map(obj=>getGroupNameTypeCount(obj)).map(obj=> obj.details).join(", "); 
		
			emits('group-selection-saved', 
				{"ids": messageGroups.checkedItems.map(n=> n.Id).join(","),
				 "all" : "0",
				 "names": selectedGroupNames.value
				}
			);
		} else {
      		let url = API_URL + "groups/?CreatedBy=" + instance.proxy.$root.userdetail.user[0].UserID;
			fetch(url, {
				method: "GET",
				headers: {
				"content-type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tknds"),
				},
			})
        	.then((response) => response.json())
        	.then((result) => {
			//Auth Token Failed
			if (result.AuthSuccess == 0) {
					instance.proxy.serverError = result.message;
					instance.proxy.$root.logout();
			} else {
				//remove unchecked items;
				result.data = result.data.filter(dbItem => !messageGroups.uncheckedItems.some(uncheckedItem => uncheckedItem.Id === dbItem.Id));

				selectedGroupsCount.value = result.data.filter(obj => obj.Status == "active").length;
				selectedGroupsIds.value = result.data.filter(obj => obj.Status == "active");
				selectedGroupNames.value = result.data.filter(obj => obj.Status == "active").map(obj=>getGroupNameTypeCount(obj)).map(obj=> obj.details).join(", "); 
				emits('group-selection-saved', 
				{"ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
				 "all" : isSelectAllGroups.value ? "1" : "0",
				 "names": selectedGroupNames.value
				}
			);
			}
        })
        .catch((e) => {
          console.log(e);
        });
    }
}

function getGroupNameTypeCount(group){
	group['details'] = group.Name + " (" + group.Group_Type + " / " + group.Member_Count + ")";
	return group;
}

function createDefaultGroup(){
		let operator = instance.proxy.$root.userdetail.user[0];
		let url = API_URL + "createdefaultgroup"
		fetch(url, {
			method: "POST",
			body: JSON.stringify(operator),
			headers: {
			"content-type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("tknds"),
			},
		})
			.then((response) => response.json())
			.then((result) => {
			selectedGroupsCount.value = 1;
			isSelectAllGroups.value = false;
			selectedGroupsIds.value = result.data;
			selectedGroupNames.value = getGroupNameTypeCount(result.data[0]).details;
			
			emits('group-selection-saved', 
						{"ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
						"all" : "0",
						"names": selectedGroupNames.value
						})
						isNewMessage.value = false;
						
			})
			.catch((e) => {
			console.log(e);
			});
}

function getGroupTypes() {
      fetch(API_URL + "group_type", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tknds"),
        },
      })
        .then((response) => response.json())
        .then((result) => {
          groupTypesList = result.map((e) => {
            return { label: e.Name, value: e.Id, isIcon: true, icon: e.Icon };
          });
          groupTypesList.unshift({
            label: "Select",
            value: "",
            isIcon: false,
            show: true,
          });
        });
      
}

function getGroups() {
      let url = API_URL +
        "groups/?CreatedBy=" + instance.proxy.$root.userdetail.user[0].UserID+"&Default_Group=1";
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tknds"),
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            //this.serverError = result.message;
            instance.proxy.$root.logout();
          } 
		  if(result.data.length == 0){
			createDefaultGroup();
		  } else {
			selectedGroupsCount.value = 1;
			isSelectAllGroups.value = false;
			selectedGroupsIds.value = result.data.filter(n=> n.Default_Group == 1);
			selectedGroupNames.value = result.data.filter(n=> n.Default_Group == 1).map(obj=>getGroupNameTypeCount(obj)).map(n=>n.details).join(", ");
			
			emits('group-selection-saved', 
				{"ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
				"all" : "0",
				"names": selectedGroupNames.value
				})
				isNewMessage.value = false;
		  }
		  
        })
        .catch((e) => {
          console.log(e);
        });
    }

onMounted(()=>{
	getGroupTypes();
	if(route.query.id){
		isNewMessage.value = false;
		let url = API_URL + "getmessagegroups/?message_id=" + route.query.id+"&createdBy="+instance.proxy.$root.userdetail.user[0].UserID;
			fetch(url, {
				method: "GET",
				headers: {
				"content-type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tknds"),
				},
			})
        	.then((response) => response.json())
        	.then((result) => {
			//Auth Token Failed
			
			if (result.AuthSuccess == 0) {
					instance.proxy.serverError = result.message;
					instance.proxy.$root.logout();
			} else {
				if (result.data.groups && result.groups.length > 0){
					selectedGroupsCount.value = result.groups.length;
					isSelectAllGroups.value = result.data.selected_all == 1;
					selectedGroupsIds.value = result.groups;
					selectedGroupNames.value = result.groups.map(obj=>getGroupNameTypeCount(obj)).map(obj=> obj.details).join(", ");
					emits('group-selection-saved', 
						{"ids": selectedGroupsIds.value.map(n=> n.Id).join(","),
						"all" : result.data.selected_all,
						"names": selectedGroupNames.value
						})
				}
			}
        })
        .catch((e) => {
          console.log(e);
        });
	} else {
		getGroups();		
	}
})



	

</script>

<style lang="scss" scoped>

.form-group {
	display: flex;
	align-items: center;
}
.form-label {
	min-width: 90px;
}

.form-control {
    padding: 13px 13px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
		width: 227px;
		position: relative;
}

.edit-icon-button {
		display: block;
    position: absolute;
    right: 5px;
    top: 6px;
}
 
	

</style>