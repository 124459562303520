<template>
  <v-row>
    <div class="w-100 mb-10">
      <v-col cols="12">
     <SuccessMessagePanel v-if="showSuccess">
        <p>
          The group type has been changed to {{groupType}}.
        </p>
      </SuccessMessagePanel>
      </v-col>
    </div>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-78 align-center">
            <div class="d-flex align-items-center">
              <span class="fs-48">{{ groupMemberCounts }}</span>
              <div class="member_section">
                <span class="mr-2"><MemberIcon /></span>
                <span class="fs-16">Members</span>
              </div>
            </div>

            <div class="group_type_list">
              <Dropdown
                :items="groupTypesList"
                :value="groupType"
                @item-selected="selectGroupType"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-sheet class="">
        <v-form class="position-relative" fast-fail @submit.prevent>
          <label class="input_label fs-12 ml-4 mb-0">Group Name</label>
         <!--  {{group}} -->
          <v-text-field 
            v-model="group.Name"
            placeholder="Name*"
            hint="supporting text"
          ></v-text-field>
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import MemberIcon from "@/components/icons/MemberIcon";
import Dropdown from "@/components/common/Dropdown.vue";
import SuccessMessagePanel from "@/components/common/SuccessMessagePanel.vue";

export default {
  components: {
    MemberIcon,
    Dropdown,
    SuccessMessagePanel
  },
  props: {
    groupInfo: {
      type: Object,
      default: () => ({})
    },
    groupMemberCounts: {
      type: Number,
      default: 0
    },
    groupTypesList: {
      type: Array,
      default: () =>{
        return []
      }
    }
  },
  data() {
    return {
      group: this.groupInfo, // Initialize from props
      groupType:this.groupTypesList?.find((e)=>e.value==this.groupInfo?.Group_Type_Id)?.label,
      showSuccess: false,
    token: localStorage.getItem("tknds") || "",

    };
  },
  methods: {
    selectGroupType(e) {
      console.log(e)
      this.group.Group_Type_Id = e.value
      this.groupType = e.label
      // Method logic
    },
    saveDetails(){
       const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
       this.showSuccess = false
      //this.groupMember = item.
      fetch(`${API_URL}update_group_info`, {
        method: "POST",
        body: JSON.stringify(this.group),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.showSuccess = true
          setTimeout(()=>{
            this.showSuccess = false
          },2000)
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  mounted(){
     const u = this.groupTypesList?.find((e)=>e.value==this.groupInfo?.Group_Type_Id)
     console.log('detail',u, this.groupTypesList)
     this.groupType = u?.label
  }
}
</script>
<style lang="scss" scoped>
.group_type_list {
  width: 120px;
  ::v-deep {
    .form-control {
      padding: 5px 13px !important;
      height: 44px !important;
      border-radius: 5px;
      border: 1px solid #bdbdbd !important;
      background: #fff !important;
      position: relative;
      z-index: 1 !important;
    }
  }
}
.member_section {
  margin-left: 20px;
}
.mb-78 {
  margin-bottom: 78px;
}
</style>