<template>
    <svg width="150" height="113" viewBox="0 0 150 113" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_4102_4332)">
		<path d="M75 94.1667C95.7107 94.1667 112.5 77.3027 112.5 56.5C112.5 35.6973 95.7107 18.8333 75 18.8333C54.2893 18.8333 37.5 35.6973 37.5 56.5C37.5 77.3027 54.2893 94.1667 75 94.1667Z" fill="white"/>
		<path d="M75 22.6C93.6113 22.6 108.75 37.806 108.75 56.5C108.75 75.194 93.6113 90.4 75 90.4C56.3887 90.4 41.25 75.194 41.25 56.5C41.25 37.806 56.3887 22.6 75 22.6ZM75 18.8333C54.2888 18.8333 37.5 35.6967 37.5 56.5C37.5 77.3033 54.2888 94.1667 75 94.1667C95.7112 94.1667 112.5 77.3033 112.5 56.5C112.5 35.6967 95.7112 18.8333 75 18.8333Z" fill="#94C2E8"/>
		<path d="M68.1384 74.7457L73.7859 57.7204L56.8359 63.393" fill="white"/>
		<path d="M68.1374 76.2524C67.9799 76.2524 67.8187 76.226 67.6612 76.1733C66.8737 75.9096 66.4499 75.0584 66.7124 74.2674L71.4149 60.1009L57.3112 64.8243C56.5274 65.088 55.6762 64.6624 55.4137 63.8714C55.1512 63.0804 55.5749 62.2291 56.3624 61.9654L73.3124 56.2891C73.8524 56.1083 74.4449 56.2514 74.8462 56.6544C75.2474 57.0575 75.3899 57.6564 75.2099 58.195L69.5587 75.2203C69.3487 75.8531 68.7637 76.2524 68.1374 76.2524Z" fill="black"/>
		<path d="M51.4194 91.5338L57.0669 74.5122L40.1206 80.1848" fill="#F7F7F7"/>
		<path d="M51.4206 93.0404C51.2631 93.0404 51.1019 93.0141 50.9444 92.9613C50.1569 92.6977 49.7331 91.8464 49.9956 91.0554L54.6981 76.889L40.5944 81.6124C39.8106 81.876 38.9594 81.4504 38.6969 80.6594C38.4344 79.8684 38.8581 79.0171 39.6456 78.7535L56.5956 73.0771C57.1356 72.8963 57.7281 73.0394 58.1294 73.4425C58.5306 73.8455 58.6731 74.4444 58.4931 74.983L52.8419 92.0084C52.6319 92.6412 52.0469 93.0404 51.4206 93.0404Z" fill="black"/>
		</g>
		<defs>
		<clipPath id="clip0_4102_4332">
		<rect width="150" height="113" fill="white"/>
		</clipPath>
		</defs>
	</svg>
</template>