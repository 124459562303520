<template>
    <v-dialog class="logged-out-dialog" v-model="dialog"  persistent  max-width="446">
        <v-card >
            <v-card-text class="card-text-con wishlist-modal">
                <div class="wishlist-modal-text">
                    <span>
                        We want you to try Sig2...<br/>
                        however, our whitelist is currently full.<br/><br/>
                    </span>
                    
                    <strong>{{usersname}}</strong> we can add you to the wait list and notify you as soon as we onboard more users.

                </div>
            </v-card-text>
            <v-card-actions class="whishlist-actions justify-content-between d-flex">
                <button @click="logout(1)" class="btn-whishlist btn">ADD ME TO THE WAITLIST</button>
                <button @click="logout(0)" class="close-btn text-btn btn">NO THANK YOU</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { VDialog, VCard, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'WishlistModal',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        usersname: String
    },
    components : {
        VDialog, VCard, VCardText, VCardActions  
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        logout(WhiteListConsent){
            let data = {
                WhiteListConsent: WhiteListConsent,
                userID: this.$root.userdetail.user[0].UserID
            }
            fetch(process.env.VUE_APP_APIDOMAIN+'/whitelistconsent', {
					method: "POST",
					body: JSON.stringify(data),
					headers: {
					"content-type": "application/json"
					}
				}).then(response => response.json())
					.then(result => {
                        console.log('result :: ', result)
						if (result.success) {
                            this.$root.logout(false);
                        }
                })
			
		}
    }
};
</script>
<style lang="scss" >
    .whishlist-actions {
        padding: 18px !important;
    }
    .card-text-con.wishlist-modal{
        padding: 18px 18px 0 !important;
         .wishlist-modal-text{
            color: #000;
            font-family: 'Roboto';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 166.667%;
            span{
                font-size: 20px;
                line-height: 30px;
            }
         }
    }
    .v-dialog>.v-card>.v-card__actions.whishlist-actions{
        padding: 0 27px 18px 18px !important;
        margin-top: 40px;
    }
    .btn-whishlist{
        padding: 9px 17px !important;
        border-radius: 3px !important;
        background: #34A853 !important;
        color: #fff !important;
        font-family: "Roboto" !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
        &:hover{
            background: #48c56a !important;
        }
    }
    .whishlist-actions .close-btn{
        color: #4F4F4F !important;
    }
</style>