<template>
    <svg width="129" height="127" viewBox="0 0 149 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M106.9 0.5H38.5301C22.4144 0.5 9.3501 13.5643 9.3501 29.68V98.05C9.3501 114.166 22.4144 127.23 38.5301 127.23H106.9C123.016 127.23 136.08 114.166 136.08 98.05V29.68C136.08 13.5643 123.016 0.5 106.9 0.5Z" fill="url(#paint0_linear_666_622)"/>
        <path d="M51.9601 92.43H38.5701C37.1501 92.43 35.9101 91.91 34.8701 90.87C33.8301 89.83 33.3101 88.59 33.3101 87.17V86.96C33.3101 85.54 33.8301 84.3 34.8701 83.26C35.9101 82.22 37.1501 81.7 38.5701 81.7H52.3201C54.0601 81.7 55.5101 81.1 56.7501 79.88C57.9701 78.66 58.5601 77.22 58.5601 75.46C58.5601 73.48 57.6501 71.96 55.7701 70.83C55.1201 70.44 53.1401 69.61 47.4801 67.83C43.2801 66.46 40.3001 64.93 38.3501 63.15C35.3801 60.48 33.8701 56.82 33.8701 52.25C33.8701 47.68 35.5401 43.55 38.8401 40.26C42.1401 36.97 46.1701 35.29 50.8301 35.29H64.2901C65.7301 35.29 66.9901 35.81 68.0201 36.85C69.0301 37.86 69.5401 39.1 69.5401 40.55V40.76C69.5401 42.17 69.0301 43.41 68.0301 44.45C66.9901 45.49 65.7301 46.02 64.2901 46.02H50.4801C48.7201 46.02 47.2801 46.61 46.0501 47.84C44.8201 49.07 44.2301 50.51 44.2301 52.27C44.2301 54.29 45.1301 55.79 46.9901 56.85C47.6001 57.19 49.5301 57.95 55.2501 59.61C59.3901 60.85 62.4801 62.37 64.4301 64.16C67.4001 66.85 68.9001 70.66 68.9001 75.48C68.9001 80.3 67.2201 84.18 63.8901 87.48C60.6001 90.77 56.5701 92.45 51.9401 92.45L51.9601 92.43Z" fill="#404040"/>
        <path d="M107.03 92.43H79.4401C77.5001 92.43 75.7001 91.37 74.7601 89.67C73.8201 87.97 73.9401 85.93 74.9001 84.24C78.0301 78.66 86.6101 69.38 91.2601 64.95C96.4501 60.01 100.92 56.51 100.92 52.24C100.92 45.66 95.3101 46.05 89.7701 46.05H81.3001C78.3501 46.05 75.9401 43.65 75.9401 40.69C75.9401 37.73 78.3401 35.33 81.3001 35.33H93.4901C107.35 35.33 111.64 45.56 111.64 52.23C111.64 60.95 106.02 65.74 100.68 70.97C97.6101 73.97 94.5201 77.49 90.4101 81.71H107.05C110 81.71 112.41 84.11 112.41 87.07C112.41 90.03 110.01 92.43 107.05 92.43H107.03Z" fill="#4992E6"/>
        <path d="M74.11 146.74C115.04 146.74 148.22 144.166 148.22 140.99C148.22 137.814 115.04 135.24 74.11 135.24C33.1802 135.24 0 137.814 0 140.99C0 144.166 33.1802 146.74 74.11 146.74Z" fill="url(#paint1_radial_666_622)"/>
        <path opacity="0.1" d="M106.9 0.5H38.5301C22.4144 0.5 9.3501 13.5643 9.3501 29.68V98.05C9.3501 114.166 22.4144 127.23 38.5301 127.23H106.9C123.016 127.23 136.08 114.166 136.08 98.05V29.68C136.08 13.5643 123.016 0.5 106.9 0.5Z" stroke="#4D4D4D" stroke-miterlimit="10"/>
        <path d="M51.9601 92.43H38.5701C37.1501 92.43 35.9101 91.91 34.8701 90.87C33.8301 89.83 33.3101 88.59 33.3101 87.17V86.96C33.3101 85.54 33.8301 84.3 34.8701 83.26C35.9101 82.22 37.1501 81.7 38.5701 81.7H52.3201C54.0601 81.7 55.5101 81.1 56.7501 79.88C57.9701 78.66 58.5601 77.22 58.5601 75.46C58.5601 73.48 57.6501 71.96 55.7701 70.83C55.1201 70.44 53.1401 69.61 47.4801 67.83C43.2801 66.46 40.3001 64.93 38.3501 63.15C35.3801 60.48 33.8701 56.82 33.8701 52.25C33.8701 47.68 35.5401 43.55 38.8401 40.26C42.1401 36.97 46.1701 35.29 50.8301 35.29H64.2901C65.7301 35.29 66.9901 35.81 68.0201 36.85C69.0301 37.86 69.5401 39.1 69.5401 40.55V40.76C69.5401 42.17 69.0301 43.41 68.0301 44.45C66.9901 45.49 65.7301 46.02 64.2901 46.02H50.4801C48.7201 46.02 47.2801 46.61 46.0501 47.84C44.8201 49.07 44.2301 50.51 44.2301 52.27C44.2301 54.29 45.1301 55.79 46.9901 56.85C47.6001 57.19 49.5301 57.95 55.2501 59.61C59.3901 60.85 62.4801 62.37 64.4301 64.16C67.4001 66.85 68.9001 70.66 68.9001 75.48C68.9001 80.3 67.2201 84.18 63.8901 87.48C60.6001 90.77 56.5701 92.45 51.9401 92.45L51.9601 92.43Z" fill="url(#paint2_linear_666_622)"/>
        <path d="M107.03 92.43H79.4401C77.5001 92.43 75.7001 91.37 74.7601 89.67C73.8201 87.97 73.9401 85.93 74.9001 84.24C78.0301 78.66 86.6101 69.38 91.2601 64.95C96.4501 60.01 100.92 56.51 100.92 52.24C100.92 45.66 95.3101 46.05 89.7701 46.05H81.3001C78.3501 46.05 75.9401 43.65 75.9401 40.69C75.9401 37.73 78.3401 35.33 81.3001 35.33H93.4901C107.35 35.33 111.64 45.56 111.64 52.23C111.64 60.95 106.02 65.74 100.68 70.97C97.6101 73.97 94.5201 77.49 90.4101 81.71H107.05C110 81.71 112.41 84.11 112.41 87.07C112.41 90.03 110.01 92.43 107.05 92.43H107.03Z" fill="url(#paint3_linear_666_622)"/>
        <defs>
            <linearGradient id="paint0_linear_666_622" x1="72.7101" y1="0.5" x2="72.7101" y2="127.23" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.27" stop-color="#EDEDED"/>
            <stop offset="0.74" stop-color="#D1D1D1"/>
            <stop offset="0.95" stop-color="#E6E6E6"/>
            </linearGradient>
            <radialGradient id="paint1_radial_666_622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(74.1096 140.784) scale(74 6)">
            <stop stop-color="#A3A3A3"/>
            <stop offset="0.13" stop-color="#A9A9A9"/>
            <stop offset="0.33" stop-color="#BABBBC"/>
            <stop offset="0.51" stop-color="#D1D3D4"/>
            <stop offset="0.69" stop-color="#E8E9EA"/>
            <stop offset="0.86" stop-color="#F9F9F9"/>
            <stop offset="1" stop-color="white"/>
            </radialGradient>
            <linearGradient id="paint2_linear_666_622" x1="51.4301" y1="35.3" x2="51.4301" y2="92.43" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0"/>
            <stop offset="0.78" stop-opacity="0.3"/>
            </linearGradient>
            <linearGradient id="paint3_linear_666_622" x1="93.2501" y1="35.34" x2="93.2501" y2="-4.23962e-05" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0"/>
            <stop offset="0.78" stop-opacity="0.3"/>
            </linearGradient>
        </defs>
    </svg>
</template>