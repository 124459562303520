<template>
    <div>
        <v-menu :disabled="disabled" v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props }">
                <v-btn v-bind:style = " disabled ? 'cursor:context-menu' : '' " class="form-control dropdown-btn" v-bind="props"  :ripple="false">
                    {{ selectedLabel }} {{selectedLabel !=  "Region Free" ? selectedLabel == "100 m" || selectedLabel == "200 m" || selectedLabel == "500 m" || selectedLabel == "100 yd" || selectedLabel == "200 yd" ? '' : itemUnit : ''}}
                    <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
 
            <v-list>
                <template v-for="(item, index) in items"  :key="item.ms || index"  >
                    <v-list-item  :value="item.value || item.label" v-if="!item.show" @click="handleItemClick(item)">
                        
                        <v-list-item-title><template v-if="item.isIcon">
                                <span class="mr-4"><component :is="item.icon"></component></span>
                            </template>{{ item.label }} {{item.value !=  "0" ? item.label == "100 m" || item.label == "200 m" || item.label == "500 m" || item.label == "100 yd" || item.label == "200 yd" ? '' : itemUnit : ''}}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
    </div>
</template>
  
  <script>
  import { VBtn, VIcon, VMenu, VList, VListItem, VListItemTitle} from 'vuetify';
   /** */ 
    import CompanyIcon from "@/components/icons/Company";
    import GroupIcon from "@/components/icons/Group";
    import EventIcon from "@/components/icons/Event";
    import PropertyIcon from "@/components/icons/Property";
    import AreaIcon from "@/components/icons/Area";
    import TeamIcon from "@/components/icons/MemberIcon";
    import ProjectIcon from "@/components/icons/Project";
    import LocationIcon from "@/components/icons/Location24";

    /** */
  export default {
    components: {  VBtn, VIcon, VMenu, VList, VListItem, VListItemTitle,CompanyIcon ,GroupIcon,EventIcon,PropertyIcon,
        AreaIcon,TeamIcon,ProjectIcon, LocationIcon
     },
    props: ['value', 'unit', 'items', 'disabled'],
    data() {
      return {
        menu: false,
        selectedLabel: this.value,
        selectedItem: this.value,
        itemUnit: this.unit,
       
      };
    },
    watch: {
        value(newValue) {
            this.selectedItem = newValue;
            this.selectedLabel = newValue;
            this.$emit('extra-event', this.items.filter(n=> n.label == newValue))
            //console.log('xxxxx = ', )
           // this.$emit('item-selected', this.items.filter(n=> n.label == newValue));  
        },
    },
    methods: {
        handleItemClick(item) {
            this.selectedLabel = item.label;
            this.selectedItem = item.value;
            this.menu = false; 
            this.$emit('item-selected', item);
        },
        blockClick(event){
            alert(1)
        }
    },
  };
  </script>
<style lang="scss">
    .dropdown-btn{
        box-shadow: none !important;
        width: 100%;
        text-align: left !important;
        &::before{
            display: none !important;
        }
        &:hover, &:focus{
            background: #fff;
            box-shadow: none;
        }
        
        .v-btn__content{
            width: 100%;
            text-align: left !important;
            justify-content: space-between !important;
            align-items: center !important;
            color:  #828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important; 
            text-transform: none !important;
            
            .v-icon{
                font-size: 26px !important;
                color: #7B8794 !important;
            }
        }
        .v-list-item{
            color:#828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important;
        }
    }
</style>