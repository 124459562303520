<template>
  <div class="search_icon">
    <span class="outline">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5 19C15.6421 19 19 15.6421 19 11.5C19 7.35786 15.6421 4 11.5 4C7.35786 4 4 7.35786 4 11.5C4 15.6421 7.35786 19 11.5 19Z"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9998 22L16.7998 16.8"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span class="outline_hover">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 5C1 2.79086 2.79086 1 5 1H21C23.2091 1 25 2.79086 25 5V21C25 23.2091 23.2091 25 21 25H5C2.79086 25 1 23.2091 1 21V5Z"
          fill="#E0E0E0"
          stroke="#E0E0E0"
          stroke-width="2"
          :class="[`icon--${variant}`]"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5 19C15.6421 19 19 15.6421 19 11.5C19 7.35786 15.6421 4 11.5 4C7.35786 4 4 7.35786 4 11.5C4 15.6421 7.35786 19 11.5 19Z"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9998 22L16.7998 16.8"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    variant: {
      default: () => {
        return "";
      },
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.outline_hover {
  display: none;
}
.search_icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    .outline_hover {
      display: block;
    }
    .outline {
      display: none;
    }
  }
}
.icon {
  &--white {
    stroke: #fff !important;
    fill: #fff !important;
  }
}
</style>