<template>
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8486_8650)">
<path d="M42.74 37.91V34.91C42.74 31.595 40.055 28.91 36.74 28.91H24.74C21.425 28.91 18.74 31.595 18.74 34.91V37.91" stroke="#25AC1E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.74 22.91C34.055 22.91 36.74 20.225 36.74 16.91C36.74 13.595 34.055 10.91 30.74 10.91C27.425 10.91 24.74 13.595 24.74 16.91C24.74 20.225 27.425 22.91 30.74 22.91Z" stroke="#25AC1E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.23499 13.835C6.21499 16.175 6.51499 19.505 4.95499 22.145" stroke="#25AC1E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.6999 25.28C18.7699 19.82 18.2449 13.7 15.2599 8.69" stroke="#25AC1E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.88 23.72C12.62 19.64 12.2 14.945 9.755 11.255" stroke="#25AC1E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_8486_8650">
<rect width="45" height="45" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>
</template>
