<template>
  <div class="succes_message d-flex" >
    <p><slot></slot></p>
  </div>
</template>
<script setup>
 
</script>

<style scoped>
.succes_message {
  background: #d6fcc4;
  padding: 10px 15px;
  align-items: center;
  gap: 10px;
  height: 50px;;
  color: #333;
  border-radius: 10px;
}
</style>