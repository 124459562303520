<template>
  <div class="hovered-btn">
    
    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" fill="#7B8794" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.6364 8L9.63636 16L6 12.3636" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


    <svg class="hovered" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z" fill="#E0E0E0" stroke="#E0E0E0" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8C4 5.79086 5.79086 4 8 4H18C20.2091 4 22 5.79086 22 8V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V8Z" fill="#7B8794" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.6364 9L10.6364 17L7 13.3636" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </div>
</template>