<template>
	<div class="message-settings">
		<div class="form-group">
			<!-- Duration -->
			<label for="locale" class="form-label">Settings</label>
			<div class="form-control" style="font-size: 15px;">
				{{messageDuration}}
			<button type="button" @click="showEditModal"><EditIcon/></button>
          	</div>
			<MessageSettingsModal 
			:showSettings="showAboutModal" 
			:_selectedDuration="custom_settings.Duration"
			:_selectedStartDate="custom_settings.StartDate"
			:_selectedEndDate="custom_settings.EndDate"
			:_selectedRepeatOption="custom_settings.RepeatOption"
			:_selectedRepeatDays="custom_settings.RepeatDays"
			:_selectedTimeStart="custom_settings.TimeStart"
			:_selectedTimeEnd="custom_settings.TimeEnd"
			:_msg_when="custom_settings.When"
			:_custom_settings="custom_settings"
			@closed="showAboutModal = false" 
			@setting-saved="onSettingSaved"/>

			<!-- <input type="text" name="Title" value="Dec 29, 2023 to Dec 30, 2023, arriving" class="form-control" style="width: 100%; background-color: #F2F2F2; border: none;"> -->
			
		</div>
	</div>
</template>

<script setup>
	import {computed, defineProps, ref, defineEmits} from 'vue';
	import EditIcon from './icons/EditIcon';
	import MessageSettingsModal from './modals/MessageSettingsModal';
	import { watch } from 'vue';
	let messageSetting1 = {};
	
	let showAboutModal = ref(false);
	let props = defineProps({
		duration_basic : String,
		custom_settings : Object,
		openonload: Boolean
	})
	let custom_settings = ref(props.custom_settings); 
	let emits = defineEmits(['setting-saved'])
	function showEditModal(){
		custom_settings.value['refresher'] = new Date().getTime();
		console.log('custom_settings = ', custom_settings.value);
		emits('setting-saved', custom_settings.value);
		showAboutModal.value = true;
	}
	let messageDuration = computed({
		get(){
			return getCustomString();
		},
		set(val){}
	})

	function getCustomString(){
		let str = "";
			//alert('here ::'+custom_settings.value.StartDate+ " | " +formatDate(custom_settings.value.StartDate))
			/* if (custom_settings.value.defaultSettings){
				str = 87654321('')+ ' to ' +formatDate(props.duration_basic) + ', ' + 'arriving';
			} else { */
				str = formatDate(custom_settings.value.StartDate);
				//alert(custom_settings.value.TimeStart)
				str += ", "+ String(custom_settings.value.TimeStart).toLowerCase().split(' ').join('');
				if (custom_settings.value.Duration != "No Limit"){
					str += " to "+ formatDate(custom_settings.value.EndDate);
					str += ", "+ String(custom_settings.value.TimeEnd).toLowerCase().split(' ').join('');
				}
				if (custom_settings.value.RepeatOption != "No"){
					str += ", repeats";
				}
				str += ", "+ String(custom_settings.value.When).toLowerCase();
			//}
			return str;
	}

	watch(()=>props.custom_settings, newSettings=>{
		console.log('newSettings = ', newSettings);
		custom_settings.value = newSettings;
		messageDuration = getCustomString();
	},{deep: true})

	watch(()=>props.openonload, newValue=>{
		showEditModal();
	})


	function isHumanReadableDate(dateString) {
		const humanReadablePattern = /^[A-Za-z]{3} \d{2}, \d{4}$/;
		return humanReadablePattern.test(dateString);
	}

	function isISO8601(dateString) {
            const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
            return iso8601Pattern.test(dateString);
        }

	//Utility functions 
	function formatDate(dateString) {
		/* if (dateString.indexOf("T") == 10){
			dateString = dateString.substr(0,10);
		} else {
			dateString = dateString.substr(0,15);
		} */
		
		let d = new Date(dateString);
		const options = { year: 'numeric', month: 'short', day: 'numeric' };
		let formattedDate = '';
			
		//alert(new Date(dateString))
		if (dateString){
			if(!isISO8601(dateString)){
				formattedDate = d.toLocaleDateString('en-US', options);
			} else {
				formattedDate = new Date(d.getTime() + (d.getTimezoneOffset()*60*1000)).toLocaleDateString('en-US', options);
			}
			
		} else {
			formattedDate = new Date().toLocaleDateString('en-US', options);
		}
		//alert(dateString+"formattedDate"+formattedDate)
		return formattedDate;
	}

	function onSettingSaved(settings){
		//alert('setting Saved :: '+ settings.StartDate + "||" +settings.EndDate)
		emits('setting-saved', settings);
	}


</script>

<style lang="scss">

.message-settings{
	position: relative;
	.form-control{
		background-color: #F2F2F2 !important;
		border: none !important;
	}
	button{
          display: block !important;
		  position: absolute;
		  right: 5px;
      	  top: 6px;
    }

}
	

</style>