<template>
    <div class="contacts-view-wrapper">
      <AddMemberForm />
    </div>
</template>
<script>
import AddMemberForm from '@/components/common/AddTeamMemberForm.vue'
export default {
    components: { AddMemberForm }
}
</script>

<style lang="scss" scoped>
.contacts-view-wrapper{
    width: 100%;
    padding-bottom: 60px;
}

</style>