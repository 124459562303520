
 <template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 30 30" style="enable-background:new 0 0 30 30; width: 30px; height:30px;" xml:space="preserve">
 
<path fill="#7B8794" class="st0" d="M25.2,13.4v-1.9c0-1.3-0.8-2.7-1.9-3.4L17,4.4C16.5,4.2,16,4,15.5,4l0,0c-0.4,0-0.9,0.1-1.2,0.3
	C13.5,4.8,13,5.7,13,6.7V11c-6.3,0.7-11,3.9-11,7.7c0,4.3,6,7.8,13.4,7.8s13.4-3.5,13.4-7.8C28.8,16.6,27.4,14.8,25.2,13.4z M15.3,6
	c0.1-0.1,0.6-0.1,0.8,0.1l6.3,3.7c0.6,0.3,1,1.1,1,1.8v4.8c0,0.3-0.1,0.6-0.4,0.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3,0-0.5-0.2
	L18,14.6L15.4,17h-0.1c-0.1,0-0.2,0-0.2-0.1C15,16.8,15,16.7,15,16.6V6.7C15,6.7,15,6.1,15.3,6z M15.4,24.6
	c-6.8,0-11.5-3.1-11.5-5.9c0-2.5,3.7-5.2,9.2-5.8v3.7c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.4,0,0.7-0.1,1-0.3l0.2-0.1
	l0.2-0.2l1.6-1.5l3.1,1.8c0.4,0.3,0.9,0.4,1.4,0.4c0.4,0,0.9-0.1,1.2-0.3c0.8-0.5,1.3-1.4,1.3-2.4v-0.6c1.1,0.9,1.7,2,1.7,3
	C26.9,21.5,22.2,24.6,15.4,24.6z"/>
<path fill="#7B8794" class="st0" d="M20.5,13.5c-0.2,0-0.3,0-0.5-0.1l-3-1.7c-0.5-0.3-0.6-0.9-0.4-1.4s0.9-0.6,1.4-0.4l3,1.7
	c0.5,0.3,0.6,0.9,0.4,1.4C21.2,13.3,20.8,13.5,20.5,13.5z"/>
</svg>
</template>
