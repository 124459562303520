<template>
    <div>
        <v-menu :disabled="disabled" v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props }">
                <div v-bind="props" style="text-align:center">
                    <SettingDots/>
                </div>
            </template>
            <v-card class="edit-settings-modal">
                <v-card-title class="modal-title-con">
                    <h5 class="modal-title">{{content.Title}}</h5>
                </v-card-title>
                        <div class="edit-container">
                            <div class="edit-row">
                                <div class="edit-item">
                                    <DurationIcon class="metadata-popup-icons"/>For {{messageMetadata.duration}}
                                </div>
                            </div>
                            
                            <div class="edit-row">
                                <div class="edit-item">
                                   
                                <CalendarIcon class="metadata-popup-icons"/>Starts {{ messageMetadata.startDate }}
                                </div>
                                <div class="edit-item" v-if="messageMetadata.endDate">
                                  <div>Ends {{ messageMetadata.endDate }}</div> 
                                </div>
                            </div>
                            
                            <div class="edit-row">
                                <div class="edit-item">
                                    <TimeIcon class="metadata-popup-icons"/>At {{messageMetadata.startTime}}
                                </div>
                                <div class="edit-item">
                                    <div>At {{ messageMetadata.endTime }}</div>
                                </div>
                            </div>

                           

                            <div class="edit-row edit-row-full">
                                <div class="edit-item"  v-if="messageMetadata.repeatOption">
                                <RepeatIcon class="metadata-popup-icons"/>
                                <div>Repeats {{ messageMetadata.repeatOption }}</div>
                                </div>
                            </div>                            
                        </div>  

                        
                        <div class="edit-links">
                            <span style="width:100%; padding-left: 33px;font-size: 16px;
                                        line-height: 2;
                                        color: #7B8794;">Last Update: {{ new Date(content.Modified).toLocaleString('en-US', last_updated_time_options) }}</span>
                            <router-link class="action-button" :to="{ name: 'ComposeMessage', query:{id: content.ContentID, settings :1 } }">
                                <EditIcon/>
                            </router-link>
                        </div>
                
                <!-- <v-card-actions class="justify-end">
                    <router-link class="action-button" :to="{ name: 'ComposeMessage', query:{id: content.message_settings.ContentID, settings :1 } }">
                      <EditIcon/>
                    </router-link>
                </v-card-actions> -->
            </v-card>


        </v-menu>
    </div>
  </template>
  
  <script setup>
    import { ref, defineProps, defineEmits, watch, computed } from 'vue';
    import SettingDots from '../icons/message_settings/SettingDots.vue';
    import EditIcon from '../icons/EditIcon.vue'
    import DurationIcon  from '../icons/message_settings/DurationIcon.vue';
    import CalendarIcon  from '../icons/message_settings/CalendarIcon.vue';
    import RepeatIcon  from '../icons/message_settings/RepeatIcon.vue';
    import TimeIcon from '../icons/message_settings/TimeIcon.vue';
    let props = defineProps(['content']);
    let emits = defineEmits(['item-selected']);
    let menu = ref(false);
    let disabled = ref(props.disabled);

    let messageMetadata = computed({
        get(){
            console.log('Content :: ', JSON.stringify( props.content ))
            // Duration
            //let duration = props.content.Expiration_Duration == "Custom" ? getCustomDuration(props.content) : props.content.Expiration_Duration;
            let duration = props.content.Expiration_Duration;
            // Start Date
            //let startDate = props.content.Expiration_Duration == "Custom" ? formatDate(props.content.message_settings.StartDate) : formatDate(props.content.Modified);
            let startDate = formatDate(props.content.StartDate);
            // Edn Date
            let endDate = "";
            if (props.content.Expiration_Duration != "No Limit"){
                //endDate = props.content.Expiration_Duration == "Custom" ? formatDate(props.content.message_settings.EndDate) : props.content.expiration_timestamp;
                endDate = formatDate(props.content.EndDate);
            } 
            // Start Time
            let startTime = "12:00 AM";
            if (typeof props.content.TimeStart != "undefined" && props.content.TimeStart != null){
                startTime = props.content.TimeStart;
            }
            let endTime = "23:59 PM";
            if (typeof props.content.TimeEnd != "undefined" && props.content.TimeEnd != null){
                endTime = props.content.TimeEnd;
            }
            let repeatOption = "";
            if (typeof props.content.RepeatOption != "undefined" 
                && props.content.RepeatOption != null
                && duration != "1 Day" 
                && duration != "1/2 Day"
            ){
                repeatOption = props.content.RepeatOption;
                if (repeatOption == "Custom"){
                    repeatOption = getCustomRepeat(props.content.RepeatDays);
                }
            }
            //alert()
            return{
                "duration" : duration,
                "startDate" : startDate,
                "endDate" : endDate,
                "startTime" : startTime,
                "endTime" : endTime,
                "repeatOption" : repeatOption,
            }
        },
        set(v){

        }
    });

    let last_updated_time_options = {
        year: 'numeric',
        month: 'short', // 'long' for full month name
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour time format
    }

    function getCustomDuration(content){
        const start = new Date(content.StartDate);
        const end = new Date(content.EndDate);
        const diffTime = Math.abs(end - start);
        const diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDay == 0 ? '1 Day' : diffDay + ' Days'; 
    }
    function getCustomRepeat(repeatDays){
        const daysName = [" Sun", " Mon", " Tue", " Wed", " Thu", " Fri", " Sat"];
        repeatDays = repeatDays.split(',');
        let retrunStr = [];
        for (let i=0; i<repeatDays.length; i++){
            if (repeatDays[i] == 1){
                retrunStr.push(daysName[i]);
            }
        }
        return retrunStr.join(',');
    }

    function formatDate(dateString) {
       
        //let d = new Date(dateString);
        //alert(dateString + "||" +d)
        //d.setHours(0, 0, 0);
		const options = { year: 'numeric', month: 'short', day: 'numeric' };
		let formattedDate = '';
        const d = new Date(dateString).getTime() + (new Date().getTimezoneOffset()*60*1000);
		if (dateString){
			formattedDate = new Date(d).toLocaleDateString('en-US', options);
		} else {
			formattedDate = new Date().toLocaleDateString('en-US', options);
		}
		return formattedDate;
	}
  </script>
<style lang="scss" scoped>
    .metadata-popup-icons{
        width: 24px;
        height: 24px;
    }
    .card-text-con{
        padding: 0px !important;
    }
    .v-row > div{
        padding-top:10px;
    }
    .dropdown-btn{
        box-shadow: none !important;
        width: 100%;
        text-align: left !important;
        &::before{
            display: none !important;
        }
        &:hover, &:focus{
            background: #fff;
            box-shadow: none;
        }
        
        .v-btn__content{
            text-align: left !important;
            justify-content: space-between !important;
            align-items: center !important;
            color:  #828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important; 
            text-transform: none !important;
            
            .v-icon{
                font-size: 26px !important;
                color: #7B8794 !important;
            }
        }
        .v-list-item{
            color:#828282 !important;
            font-family: 'Roboto' !important;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px !important;
        }
    }
    .edit-settings-modal {
        width: 430px;
        height: 300px !important;
        padding: 24px 10px 10px 23px;
        display: flex;
        flex-direction: column;
        & .modal-title-con {
            padding: 0;
            
            margin-bottom: 28px;
            & .modal-title {
                font-size: 16px;
            line-height: 1;
            font-weight: 500;
            color: #333333;
            max-width: 328px;
            overflow: hidden;
            text-overflow: ellipsis;
            }
        }
        & .edit-container {
            display: flex;
            flex-direction: column;
            gap: 24px;
            & .edit-row {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                &.edit-row-full {
                    grid-template-columns: 1fr;
                }
            }
            & .edit-item {
            font-size: 16px;
            line-height: 1;
            color: #828282;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 9px;
        }
            
        }
        & .edit-links {
                margin-top: auto;
                display: flex;
                justify-content: flex-end
            }
        
    }
</style>