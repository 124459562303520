<template>
  <div class="reload_caret">
    <span class="ouline_caret">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 30 30"
        fill="none"
      >
       
        <path
          d="M22.5304 5.20234L22.5942 11.202L16.5945 11.2658"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.0542 15.4996C22.9948 19.5079 20.2915 22.9937 16.4241 24.0489C12.5568 25.104 8.45772 23.4741 6.37086 20.0514C4.28401 16.6287 4.71248 12.2384 7.42174 9.28371C10.131 6.32906 14.9696 5.55979 18.0582 7.30547C21.1468 9.05115 21.4587 10.761 21.4587 10.761"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span class="ouline_hover_caret">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 6C0 2.68629 2.68629 0 6 0H24C27.3137 0 30 2.68629 30 6V24C30 27.3137 27.3137 30 24 30H6C2.68629 30 0 27.3137 0 24V6Z"
          fill="#E0E0E0"
           :class="[`icon--${variant}`]"
        />
        <path
          d="M22.5304 5.20234L22.5942 11.202L16.5945 11.2658"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.0542 15.4996C22.9948 19.5079 20.2915 22.9937 16.4241 24.0489C12.5568 25.104 8.45772 23.4741 6.37086 20.0514C4.28401 16.6287 4.71248 12.2384 7.42174 9.28371C10.131 6.32906 14.9696 5.55979 18.0582 7.30547C21.1468 9.05115 21.4587 10.761 21.4587 10.761"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    variant: {
      default: () => {
        return "";
      },
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
.reload_caret {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ouline_hover_caret {
    display: none;
  }
  &:hover {
    .ouline_caret {
      display: none;
    }
    .ouline_hover_caret {
      display: block;
    }
  }
}
.icon {
  &--white {
    fill: #fff !important;
  }
}
</style>
