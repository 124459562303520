<template>
  <!-- <v-data-table-virtual
    :headers="headers"
    :items="virtualBoats"
    height="400"
    item-value="name"
  >
  
  </v-data-table-virtual> -->
  <div class="w-100">
  <v-table class="contact_member w-100">
    <tbody>
      <tr v-for="(item, index) in contacts" :key="index">
        <td>
          <div class="d-flex fgg align-center justify-space-between">
            <div class="d-flex align-center">
              <v-checkbox
                v-if="showCheckbox"
                :disabled="activateGroups"
                hide-details
                @click="$emit('update-checked', { e: $event, item })"
                v-model="item.checked"
              ></v-checkbox
              ><label class="contact_name font-size-16 mx-3"
                >{{ index + 1 }}. &nbsp;
                {{ item.FirstName + " " + item.LastName }}</label
              >
            </div>
            <a href="javascript:void(0)" v-if="isRemove" @click="openDialog(item)"
              > <img :src="require('@/assets/images/groups/cross.svg')"   /></a>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
    <v-dialog
      v-model="confirmDialog"
      max-width="400"
      persistent
    >
      

      <v-card
        prepend-icon="mdi-map-marker"
                title="Are you sure"
      >
        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn @click="confirmDialog = false">
            No
          </v-btn>

          <v-btn @click="confirmRemove">
            Yes
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    contacts: {
      default: () => {
        return [];
      },
      type: Array,
    },
    activateGroups: {
      default: () => {
        return false;
      },
      type: Boolean,
    },
    showCheckbox: {
      default: () => {
        return true;
      },
      type: Boolean,
    },
    isRemove: {
      default: () => {
        return false;
      },
      type: Boolean,
    },
  },
  computed: {
    virtualBoats() {
      return [...Array(50).keys()].map((i) => {
        const boat = { ...this.boats[i % this.boats.length] };
        boat.name = `${boat.name} #${i}`;
        return boat;
      });
    },
  },
  data: () => ({
    model: "Foo",
    contact:null,
    confirmDialog: false,

  }),
  methods:{
    openDialog(item){
      this.contact = item
      this.confirmDialog = true
      
    },
    confirmRemove(){
      // console.log('sdfsa',this.contact);
      this.$emit('remove-contact', this.contact)
    }
  }
};
</script>
<style lang="scss" scoped>
.contact_member {
  .contact_name {
     
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #000;
  }
  ::v-deep {
    .v-table__wrapper {
      // background: #000;
    }
    td {
      padding: 0 !important;
    }
  }
}
</style>
