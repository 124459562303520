<template>
  <div class="hovered-btn">
 
    <svg class="default" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 17.66V23C24 24.1046 23.1046 25 22 25H8C6.89543 25 6 24.1046 6 23V9C6 7.89543 6.89543 7 8 7H13.34" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6L25 10L15 20H11V16L21 6Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg  class="hovered" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5C0 2.23858 2.23858 0 5 0H25C27.7614 0 30 2.23858 30 5V25C30 27.7614 27.7614 30 25 30H5C2.23858 30 0 27.7614 0 25V5Z" fill="#E0E0E0"/>
      <path d="M24 17.66V23C24 24.1046 23.1046 25 22 25H8C6.89543 25 6 24.1046 6 23V9C6 7.89543 6.89543 7 8 7H13.34" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6L25 10L15 20H11V16L21 6Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
 
  </div>
</template>