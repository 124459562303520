<template>
  <div class="hovered-btn">

    <svg class="default" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 25.8C15.7703 25.8 16.8 24.7703 16.8 23.5C16.8 22.2297 15.7703 21.2 14.5 21.2C13.2297 21.2 12.2 22.2297 12.2 23.5C12.2 24.7703 13.2297 25.8 14.5 25.8Z" stroke="#7B8794" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5 17.3C15.7703 17.3 16.8 16.2703 16.8 15C16.8 13.7297 15.7703 12.7 14.5 12.7C13.2297 12.7 12.2 13.7297 12.2 15C12.2 16.2703 13.2297 17.3 14.5 17.3Z" stroke="#7B8794" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5 8.8C15.7703 8.8 16.8 7.77026 16.8 6.5C16.8 5.22975 15.7703 4.2 14.5 4.2C13.2297 4.2 12.2 5.22975 12.2 6.5C12.2 7.77026 13.2297 8.8 14.5 8.8Z" stroke="#7B8794" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


    <svg class="hovered" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.75C8 1.67893 9.67893 0 11.75 0H17.25C19.3211 0 21 1.67893 21 3.75V26.25C21 28.3211 19.3211 30 17.25 30H11.75C9.67893 30 8 28.3211 8 26.25V3.75Z" fill="#E0E0E0"/>
<path d="M14.5 25.8C15.7703 25.8 16.8 24.7703 16.8 23.5C16.8 22.2297 15.7703 21.2 14.5 21.2C13.2297 21.2 12.2 22.2297 12.2 23.5C12.2 24.7703 13.2297 25.8 14.5 25.8Z" stroke="#7B8794" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 17.3C15.7703 17.3 16.8 16.2703 16.8 15C16.8 13.7297 15.7703 12.7 14.5 12.7C13.2297 12.7 12.2 13.7297 12.2 15C12.2 16.2703 13.2297 17.3 14.5 17.3Z" stroke="#7B8794" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 8.8C15.7703 8.8 16.8 7.77026 16.8 6.5C16.8 5.22975 15.7703 4.2 14.5 4.2C13.2297 4.2 12.2 5.22975 12.2 6.5C12.2 7.77026 13.2297 8.8 14.5 8.8Z" stroke="#7B8794" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


 
  </div>
</template>