<template>
<span @click="emitClicked">

 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 8.13401 4.13401 5 8 5H16C19.866 5 23 8.13401 23 12V12C23 15.866 19.866 19 16 19H8C4.13401 19 1 15.866 1 12V12Z" stroke="#B10000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 15C9.65685 15 11 13.6569 11 12C11 10.3431 9.65685 9 8 9C6.34315 9 5 10.3431 5 12C5 13.6569 6.34315 15 8 15Z" fill="#B10000" stroke="#B10000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
</template>
<script>
export default {
  props: {
    width: {
      default: () => {
        return 25;
      },
      type: Number,
    },
    height: {
      default: () => {
        return 25;
      },
      type: Number,
    },
  },
   methods:{
    emitClicked(e){
      this.$emit('clicked', e)
    }
  }
};
</script>
