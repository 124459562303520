<template>
  <div class="hovered-btn">

    <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5421 4.78311L18.5843 8.75417L14.6132 8.79637" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.9348 12.0294C18.8896 15.0841 16.8294 17.7407 13.8821 18.5448C10.9347 19.3489 7.81086 18.1068 6.22046 15.4983C4.63007 12.8899 4.95661 9.54401 7.02133 7.29227C9.08605 5.04053 12.7735 4.45427 15.1274 5.78465C17.4812 7.11503 17.7189 8.41811 17.7189 8.41811" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


    <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#E0E0E0"/>
      <path d="M18.5421 4.78311L18.5843 8.75417L14.6132 8.79637" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.9349 12.0294C18.8896 15.0841 16.8294 17.7407 13.8821 18.5448C10.9347 19.3489 7.81087 18.1068 6.22048 15.4983C4.63009 12.8899 4.95663 9.54401 7.02135 7.29227C9.08607 5.04053 12.7736 4.45427 15.1274 5.78465C17.4812 7.11503 17.7189 8.41811 17.7189 8.41811" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </div>
</template>