<template>
<span @click="emitClicked">
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="toggle-on">
      <path
        id="Rectangle-path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 12C1 8.13401 4.13401 5 8 5H16C19.866 5 23 8.13401 23 12C23 15.866 19.866 19 16 19H8C4.13401 19 1 15.866 1 12Z"
        fill="#7B8794"
        stroke="#7B8794"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Oval"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 15C17.6569 15 19 13.6569 19 12C19 10.3431 17.6569 9 16 9C14.3431 9 13 10.3431 13 12C13 13.6569 14.3431 15 16 15Z"
        fill="white"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</span>
</template>
<script>
export default {
  props: {
    width: {
      default: () => {
        return 24;
      },
      type: Number,
    },
    height: {
      default: () => {
        return 24;
      },
      type: Number,
    },
  },
   methods:{
    emitClicked(e){
      this.$emit('clicked', e)
    }
  }
};
</script>
