<template>
    <v-dialog class="logged-out-dialog" v-model="dialog"  persistent  max-width="450">
        <v-card >
            <v-toolbar class="modal-header error-modal">
                <v-toolbar-title class="error-modal">Attention</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="card-text-con">
                <div class="about-card-text error-modal">
                    Sorry, there is a problem.
                    <br/><br/>
                    <div class="error-con" >
                        <pre>{{ smsErrorNotification }}</pre>
                    </div>
                    <br/>
                    If the phone number is incorrect you can fix it in your profile.
                    <br/><br/>
                    <a href="mailto:support@sig2labs.com" class="nav-link">support@sig2labs.com</a>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <button @click="close" class="close-btn text-btn btn ms-auto">Close</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { VDialog, VCard,VToolbarTitle,VToolbar, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'ErrorModal',
    props: {
        smsErrorNotification: String,
        value: {
            type: Boolean,
            default: false
        },
    },
    components : {
        VDialog,   VCard, VCardText, VToolbar, VToolbarTitle, VCardActions  
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        close() {
                this.dialog = false;
            }
    }
};
</script>
<style lang="scss" >
    .modal-header.error-modal{
        background: #EBC7C7 !important;
    }
    .card-text-con{
         padding: 25px 10px 0 16px !important;
         .about-card-text.error-modal, .about-card-text.error-modal pre{
             color: #000;
             font-family: 'Roboto';
             font-size: 14px;
             font-style: normal;
             font-weight: 400;
             line-height: 114.286%;
             .nav-link{
                color:   #2F80ED ;
             }
         }
         .error-con{
            max-width: 80%;
         }
    }
    .v-toolbar__title{
        color: #000;
        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: .01em;
    }
</style>