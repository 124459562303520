<template>
  <!-- <div class="router-view-wrapper" v-if="showlogin || otherPages"> -->
    <div class="router-view-wrapper" v-if="showlogin || otherPages">
    <div class="landing-page app-container d-flex justify-content-between">
      <div class="landing-text-section" v-if="!overlayPages">
        <h2 class="font-roboto text-primary">Smart Reminders <br/> <span>Right place. Right time.</span></h2>
        <h4 class="font-roboto">Location-based audio messages <br/>
          <span>for work on properties and job sites <br/>
            when timing is everything.</span>
        </h4>
      </div>
      <div class="landing-login-form-section" v-if="!overlayPages">
        <AuthHome @btnClick="handleBtnClick" />
      </div>
      <!-- <div v-else class="landing-login-form-section">
        <Auth/>
      </div> -->
    </div>
    <Footer :classValue="'home-footer'"/>
  </div>

  <div v-if="!showlogin && !otherPages && afterloginpage" style="width: 100%; height: 100%; position: fixed; top:0px; left:0px; background: #f2f2f2;">
    
    <Header :user="''"/>
  </div>
</template>

<script>
//import Auth from '../components/Auth.vue'
import AuthHome from '../components/Home.vue'
import Footer from '../components/common/Footer.vue'
import Header from '../components/common/Header.vue'
import { ref } from 'vue';
export default {
  name: 'HomeView',
  components: {AuthHome, Footer,Header },
  data() {
    return {
      showlogin: false,
      showAuthHome: true,
      otherPages:false,
      afterloginpage:false
    }
	},
  methods: {
    handleBtnClick(action) {
      window.location.href = '/login';
    }
  },
  created(){

    setTimeout(()=>{
      this.showlogin = window.location.pathname == "/";
      this.otherPages = window.location.pathname.indexOf('/terms') != -1 ||
                        window.location.pathname.indexOf('/privacy') != -1 ||
                        window.location.pathname.indexOf('/logout') != -1 ||
                        window.location.pathname.indexOf('/authnotallowed') != -1;
      this.overlayPages = window.location.pathname.indexOf('/terms') != -1 ||
                        window.location.pathname.indexOf('/privacy') != -1;
      this.afterloginpage = true;                
      //alert(ref(this.$router.currentRoute).value.path)
      //ref(this.$router.currentRoute).value.path == '/' 
      //this.otherPages = ref(this.$router.currentRoute).value.path == '/terms' ||
        //               ref(this.$router.currentRoute).value.path == '/privacy'
    }, 10)
  }
}
</script>

<style lang="scss" scoped>

.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
</style>
 
