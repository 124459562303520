<template>
  <div>
    <v-row
      class="position-relative"
      :class="[showConfirm ? 'remove-confirm' : '']"
    >
      <div class="w-100 position-absolute" v-if="showConfirm">
        <ConfirmPanel
          :class="{ disabled: loading }"
          :text="`Remove ${selectedContacts} Members`"
          @confirmation-cancel="cancel"
          @confirmation-success="removeMembers"
        />
      </div>
      <div
        class="w-100 position-absolute"
        v-if="selectedContacts > 0 && !showConfirm && showSelectedInfo"
      >
        <div
          class="d-flex align-center justify-space-between selected_member_count"
        >
          <div class="counts">{{ selectedContacts }} Selected</div>
          <div class="info underline cursor_pointer" @click="confirmPopup()">
            <u>Remove Members</u>
          </div>
        </div>
      </div>
      <S2gTable
        :indeterminate="indeterminate"
        :items="groupMembers"
        :selectedItemCount="selectedContacts"
        :disabledColumn="disabledColumn"
        :headers="[{ key: null, title: null }]"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
        ]"
        @column-filter="handleFilter"
        @toggle-row-select="selectMembers"
        @search="searchMember"
        @show-info="showInfo = true"
        :infoMessage="'Members who are removed, will not receive the messages assigned to this group.'"
        :isReload="false"
        :isSelectGlobalCheckBox="isSelectAll"
        :isDisabledAllCheckBox="disabledCheckBox"
        :isRemove="true"
        :totalItems="`${memberPage.pager.totalItems}`"
        :totalCountText="`${group.Name ? 'in ' + group.Name : ' Contacts'}`"
        :totalCountText1="`${group.Name ? 'in ' + group.Name : ' Contact'}`"
        :showCount="true"
        :page="(memberPage.currentPage - 1) * memberPage.itemsPerPage"
        @select-all="selectAllMember"
      >
        <template v-slot:table_body="{ item }">
          <td>
            <div class="d-flex justify-space-between">
              <span
                >{{ item.item.FirstName }}
                {{ item.item.LastName }}
              </span>
            </div>
          </td>
          <td align="right">
            <div class="flex items-center justify-end">
              <span
                class="cursor_pointer"
                :class="{ disabled: disabledCheckBox }"
                @click="confirmRemoveMember(item.item)"
              >
                <Remove v-if="!showConfirm || !item.item.checked" />
                <RemoveSolid v-if="showConfirm && item.item.checked" />
              </span>
            </div>
          </td>
        </template>
      </S2gTable>
      <div class="pagination mt-4">
        <Paginaiton
          v-if="memberPage.pager.totalPages > 1"
          :currentPage="memberPage.currentPage"
          :totalItems="memberPage.pager.totalItems"
          :itemsPerPage="memberPage.itemsPerPage"
          :totalPages="memberPage.pager.totalPages"
          @page-changed="changePagination"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import S2gTable from "@/components/common/S2gTable.vue";
import ConfirmPanel from "@/components/common/ConfirmPanel.vue";
import RemoveSolid from "@/components/icons/RemoveSolid";
import Remove from "@/components/icons/Remove";
import Paginaiton from "../../components/common/Paginaiton.vue";
import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";

export default {
  inject: ["eventBus"],
  components: {
    S2gTable,
    ConfirmPanel,
    Remove,
    Paginaiton,
    RemoveSolid,
  },
  props: {
    group: {
      default: () => {
        return [];
      },
      type: Array,
    },
    defaultMember: {
      default: () => {
        return { selectedM: [], uselectedM: [] };
      },
      type: Object,
    },
    isGroupEdit: {
      default: () => {
        return true;
      },
      type: Boolean,
    },
    selectedContactItems: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },

  data: () => ({
    groupMembers: [],
    dialog: false,
    loading: false,
    showInfo: false,
    showSelectedInfo: true,
    isSelectAll: false,
    isSelectAllTemp: false,
    selectedMembers: 0,
    indeterminate: false,
    tab: null,
    memberPage: {
      pager: { totalPages: 0, totalItems: 0 },
      currentPage: 1,
      itemsPerPage: 10,
    },

    token: localStorage.getItem("tknds") || "",
    selectedContacts: 0,
    showConfirm: false,
    searchMemberText: "",
    selectedContactIds: [],
    uncheckedMembers: [],
    checkedMember: [],
    disabledColumn: [],
    confirmDelete: false,
    disabledCheckBox: false,
  }),
  methods: {
    confirmPopup() {
      this.showConfirm = true;
      this.showSelectedInfo = false;
      this.disabledCheckBox = true;
      this.$emit("removeMember");
      this.disabledColumn = { first_col: true, question_icon: true };
      this.groupMembers = this.groupMembers.map((e) => {
        if (e.checked) {
          e.checkdClass = "remove_selected";
        }
        return e;
      });
    },
    confirmRemoveMember(val) {
      this.confirmPopup();
      this.groupMembers = this.groupMembers.map((e) => {
        if (e.checked) {
          e.checkdClass = "remove_selected";
        }
        if (e.UserID == val.UserID) {
          e.singleRemoveClass = "remove_selected";
        }
        return e;
      });
      const u = this.groupMembers.find((e) => e.Id == val.Id || e.checked);

      if (!u) {
        this.groupMembers = this.groupMembers.map((e) => {
          e.checked = false;
          e.singleRemoveClass = "";
          e.checkdClass = "";

          return e;
        });
        this.reset();
        this.selectedContacts = 1;
        this.checkedMember.push(val);
      }
      this.selectedContacts = 1;
      const v = this.groupMembers.find((e) => e.Id == val.Id && !e.checked);
      if (v) {
        this.checkedMember.push(val);
      }
      this.showSelectedInfo = false;
      this.disabledColumn = { first_col: true, question_icon: true };

      console.log(this.isSelectAll, this.groupMembers);
      //this.selectMembers({e:{target:{checked:true}}, item:val})
    },
    handleFilter(e) {
      const event = e.e;
      if (event.value == "all") {
        this.selectAllMember({ target: { checked: true } });
      } else {
        this.selectAllMember({ target: { checked: false } });
      }
    },
    getGroupmembers() {
      console.log('ooooo', this.selectedContactItems)
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      const req = {
        page: this.memberPage.currentPage,
        itemPerPage: this.memberPage.itemsPerPage,
        search: this.searchMemberText,
        contacts: JSON.stringify(this.selectedContactItems),
        sort: 'FirstName',
        ClientID:this.$root.userdetail.user[0].ClientID
        // excludeMember: this.checkedMember.map((e) => e.UserID),
        // uncheckedMembers: this.uncheckedMembers.map((e) => e.UserID),
        // isSelectAll: this.isSelectAllTemp,
      };
      if (this.confirmDelete == true) {
        req.excludeMember = this.checkedMember.map((e) => e.UserID);
        req.uncheckedMembers = this.uncheckedMembers.map((e) => e.UserID);
        req.isSelectAll = this.isSelectAllTemp;
      }
      //this.groupMember = item.
      fetch(`${API_URL}get_group_members?group_id=${this.group.Id}`, {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.groupMembers = result.data.map((e) => {
            e.checked = manageRowDefaultSelection(
              this.isSelectAll,
              e,
              this.checkedMember,
              this.uncheckedMembers,
              "UserID"
            );
            e.class = "hover_item";
            e.checkdClass = "member_selected";
            return e;
          });
          this.memberPage.pager = result.page.pager;
          this.groupMembers = this.groupMembers.filter(
            (e) => !this.checkedMember.some((u) => u.UserID == e.UserID)
          );
          this.$emit("update-member", this.memberPage.pager.totalItems);
          this.dialog = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateSelectMembers(e) {},
    cancel() {
      console.log("ddd no");
      this.showConfirm = false;
      this.disabledCheckBox = false;
      if (this.isGroupEdit) {
        this.reset();
        this.$emit("removeMember");
        this.$emit("enableSave");
        this.disabledColumn = {};
      }
      this.$emit("enableSave");
    },
    removeMembers() {
      this.showConfirm = false;
      this.confirmDelete = true;
      const checkedMember = this.groupMembers.filter((e) => e.checked);
      const singleRowMember = this.groupMembers.filter(
        (e) => e.singleRemoveClass && e.singleRemoveClass != ""
      );
      if (checkedMember.length == 0 && singleRowMember.length > 0) {
        this.selectedContacts = this.selectedContacts + 1;
      }

      this.groupMembers = this.groupMembers.filter((e) => {
        if (!e.checked && !e.singleRemoveClass) {
          //e.hide = true
          return e;
        }
      });
      this.softReset();
      this.$emit("enableSave");
      if (this.isGroupEdit) {
        this.getGroupmembers();
      }
      this.showSelectedInfo = true;
      // this.memberPage.itemsPerPage =
      //   this.memberPage.itemsPerPage - this.selectedContacts;
      // this.memberPage.pager.totalItems =
      //   this.memberPage.pager.totalItems - this.selectedContacts;
      this.selectedContacts = 0;
    },
    softReset() {
      this.disabledCheckBox = false;
      this.groupMembers = this.groupMembers.map((e) => {
        e.checked = false;
        e.checkdClass = "";
        e.singleRemoveClass = undefined;
        return e;
      });
      //this.selectAllMember({ target: { checked: false } });
      this.indeterminate = false;
      this.isSelectAll = false;
      this.disabledColumn = { first_col: false, question_icon: false };
      this.$emit("removeMemberDone");
    },
    //calls when maanging member while adding group
    unselectedMembers() {
      this.eventBus.emit("remove-group-member", {
        selectedMember: this.checkedMember.map((e) => e.UserID),
        unselectedMember: this.uncheckedMembers.map((e) => e.UserID),
        isSelectAll: this.isSelectAll,
      });
      this.confirmDelete = false;
    },
    removeAPi() {
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      const req = {
        group_id: this.group.Id,
        selectedMember: this.checkedMember.map((e) => e.UserID),
        unselectedMember: this.uncheckedMembers.map((e) => e.UserID),
        // isSelectAll: this.isSelectAll,
        isSelectAll: this.isSelectAllTemp,
      };
      this.memberPage = {
        pager: { totalPages: 0, totalItems: 0 },
        currentPage: 1,
        itemsPerPage: 10,
      };
      this.loading = true;
      fetch(`${API_URL}remove_group_members?group_id=${this.group.Id}`, {
        method: "DELETE",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          try {
            this.confirmDelete = false;
            this.disabledCheckBox = false;
            this.showSelectedInfo = true;
            this.showConfirm = false;
            this.showSelectedInfo = false;
            this.confirmDelete = false;
            this.showConfirm = false;
            this.loading = false;
            this.isSelectAllTemp = false;
            this.indeterminate = false;
            this.getGroupmembers();
            this.$emit("removeMemberDone");
            this.pager = result.pager;
            this.dialog = true;
            this.reset();
            this.showSelectedInfo = true;
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showConfirm = false;
        });
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    selectMembers(e) {
      let event = e.e;
      const u = manageSingleChecked(
        event,
        e.item,
        "UserID",
        this.groupMembers,
        this.selectedContacts,
        this.isSelectAll,
        this.memberPage.pager.totalItems
      );
      this.selectedContacts = u.selectedCounts;
      this.indeterminate = u.indeterminate;
      this.isSelectAll = u.isSelectAll;
      this.isSelectAllTemp = u.isSelectAll;
      if (this.selectedContacts > 0) {
        this.disabledColumn.question_icon = true;
      } else {
        this.disabledColumn.question_icon = false;
      }
      manageSelectedItems(event, e.item, "UserID", this.checkedMember);
      manageUnSelectedItems(event, e.item, "UserID", this.uncheckedMembers);
    },
    selectAllMember(e) {
      this.isSelectAll = e.target.checked;
      this.isSelectAllTemp = e.target.checked;
      this.checkedMember = [];
      this.uncheckedMembers = [];
      if (!e.target.checked) {
        this.selectedContactIds = [];
        this.selectedContacts = 0;
        this.indeterminate = false;
        this.groupMembers = this.groupMembers.map((e) => {
          e.checked = false;
          return e;
        });
      } else {
        this.indeterminate = false;
        this.selectedContacts = this.memberPage.pager.totalItems;
        this.groupMembers = this.groupMembers.map((e) => {
          e.checked = true;
          return e;
        });
        this.selectedContactIds = this.groupMembers.map((r) => r.Id);
      }
    },
    searchMember(val) {
      this.searchMemberText = val;
      if (this.isGroupEdit) {
        this.getGroupmembers();
      } else {
        this.getContacts();
      }
    },
    changePagination(page) {
      if (page != this.memberPage.currentPage) {
        this.memberPage.currentPage = page;
        console.log("sdd");
        if (this.isGroupEdit) {
          this.getGroupmembers();
        } else {
          this.getContacts();
        }
      }
    },
    reset() {
      this.loading = false;
      this.isSelectAll = false;
      this.indeterminate = false;
      this.showSelectedInfo = true;
      this.disabledCheckBox = false;
      this.selectedContacts = 0;
      this.selectedContactIds = [];
      this.uncheckedMembers = [];
      this.checkedMember = [];
      this.disabledColumn = {};
      this.groupMembers = this.groupMembers.map((e) => {
        e.checked = false;
        e.checkdClass = "";
        e.singleRemoveClass = "";
        return e;
      });
    },
    getContacts() {
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      let url =
        API_URL +
        "teams/?page=" +
        this.memberPage.currentPage +
        "&perpage=" +
        this.memberPage.itemsPerPage +
        "&search=" +
        this.searchMemberText;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      if (this.defaultMember.selectedM.length > 0) {
        url += "&UserID=" + JSON.stringify(this.defaultMember.selectedM);
      }
      if (this.defaultMember.uselectedM.length > 0) {
        url += "&NotUserID=" + JSON.stringify(this.defaultMember.uselectedM);
      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            this.groupMembers = result.teams.docs.map((e) => {
              e.checked = manageRowDefaultSelection(
                this.isSelectAll,
                e,
                this.checkedMember,
                this.uncheckedMembers,
                "UserID"
              );
              e.class = "hover_item";
              e.checkdClass = e.checked ? "member_selected" : "";
              return e;
            });
            console.log("ppp---ppp", this.selectedMember);

            this.memberPage.pager = result.teams.pager;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    if (this.isGroupEdit) {
      this.getGroupmembers();
    } else {
      this.getContacts();
    }
  },
};
</script>
<style lang="scss" scoped>
.selected_member_count {
  border-radius: 10px;
  font-weight: 500;
  color: #000;
  width: 100%;
  background: #f2f2f2;
  margin-bottom: 0px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 17px;
  padding-bottom: 17px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.top_11 {
  top: 2px;
}
.info {
  color: #4783e6;
}
::v-deep .s2g-common-table {
  thead {
    tr {
      border-bottom: 2px solid #000;
    }
  }
}
</style> 