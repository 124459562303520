<template>
    <svg width="150" height="113" viewBox="0 0 150 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4102_4341)">
        <path d="M75.0004 90.7428C93.8285 90.7428 109.092 75.4118 109.092 56.5C109.092 37.5882 93.8285 22.2572 75.0004 22.2572C56.1724 22.2572 40.9092 37.5882 40.9092 56.5C40.9092 75.4118 56.1724 90.7428 75.0004 90.7428Z" fill="white"/>
        <path d="M75 22.2572C93.8287 22.2572 109.091 37.5876 109.091 56.5C109.091 75.4124 93.8287 90.7428 75 90.7428C56.1713 90.7428 40.9087 75.4124 40.9087 56.5C40.9087 37.5876 56.1713 22.2572 75 22.2572ZM75 18.8333C64.9838 18.8333 55.5675 22.7507 48.4837 29.8659C41.4 36.9811 37.5 46.4392 37.5 56.5C37.5 66.5608 41.4 76.0189 48.4837 83.1341C55.5675 90.2493 64.9838 94.1667 75 94.1667C85.0162 94.1667 94.4325 90.2493 101.516 83.1341C108.6 76.0189 112.5 66.5608 112.5 56.5C112.5 46.4392 108.6 36.9811 101.516 29.8659C94.4325 22.7507 85.0162 18.8333 75 18.8333Z" fill="#94C2E8"/>
        <path d="M103.44 39.2826L109.092 22.2572L92.1416 27.9336" fill="white"/>
        <path d="M103.44 40.7892C103.283 40.7892 103.121 40.7629 102.964 40.7101C102.176 40.4465 101.753 39.5952 102.015 38.8042L106.718 24.6378L92.6139 29.3612C91.8302 29.6248 90.9789 29.1992 90.7164 28.4082C90.4539 27.6172 90.8777 26.7659 91.6652 26.5023L108.615 20.8259C109.155 20.6451 109.748 20.7882 110.149 21.1913C110.55 21.5943 110.693 22.1932 110.513 22.7318L104.861 39.7572C104.651 40.39 104.066 40.7892 103.44 40.7892Z" fill="black"/>
        <path d="M86.7236 56.0744L92.3748 39.049L75.4248 44.7254" fill="white"/>
        <path d="M86.7263 57.581C86.5688 57.581 86.4075 57.5547 86.25 57.5019C85.4625 57.2383 85.0388 56.387 85.3013 55.596L90.0038 41.4296L75.9 46.153C75.1163 46.4166 74.265 45.991 74.0025 45.2C73.74 44.409 74.1638 43.5577 74.9513 43.2941L91.9013 37.6177C92.4413 37.4369 93.0338 37.58 93.435 37.9831C93.8363 38.3861 93.9788 38.985 93.7988 39.5236L88.1475 56.549C87.9375 57.1818 87.3525 57.581 86.7263 57.581Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0_4102_4341">
        <rect width="150" height="113" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>