<template>
  <div>
    <div class="router-view-wrapper">
      <div class="landing-page app-container d-flex justify-content-between">
        <div class="landing-text-section">
        <h2 class="font-roboto text-primary">Smart Reminders <br/> <span>Right place. Right time.</span></h2>
         
      </div>
    
      </div>
      <Footer :classValue="'home-footer'"/>
    </div>
    <div data-app>
        <LoggedoutModal v-model="showModal" />
    </div>
  </div>
</template>

<script>
import Footer from '../components/common/Footer.vue'
import LoggedoutModal from './modals/LoggedoutModal.vue'
export default {
  name: 'HomeView',
  components: { Footer, LoggedoutModal },
  data() {
    return {
      showlogin: false,
      showModal: true
    }
	},
  created(){
    setTimeout(()=>{
      localStorage.clear();
      this.showlogin = this.$route.path == '/auth'
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
</style>